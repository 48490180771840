






























































































import { fetchApi } from "@/lib/fetch";
import Vue, { nextTick } from "vue";
import { CheckSquareIcon, ChevronRightIcon } from "vue-feather-icons";
import router from "@/router";

export default Vue.extend({
  name: "VehiclesOverview",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
  },
  data() {
    return {
      // table
      fields: [
        { key: "licensePlate", label: "Kennzeichen", sortable: false },
        { key: "state", label: "Status", sortable: false },
        { key: "name", label: "Name", sortable: false },
        { key: "modelName", label: "Modell", sortable: false },
        { key: "bodyType", label: "Karosserie", sortable: false },
        { key: "class", label: "Klasse", sortable: false },
        {
          key: "passengerCapacity",
          label: "Kapazität",
          sortable: false,
        },
        //{ key: "packageCapacity", label: "Lieferkapazität", sortable: false },
        { key: "labels", label: "Labels", sortable: false },
        { key: "updatedAt", label: "Letztes Update" },
      ],
      offlineStates: ["offline", "online", "activating"],
      onlineStates: ["idle", "in-ride", "in-job"],
      paragraph42Vehicles: [],
      otherVehicles: [],
      isOperator: false,
      max42Vehicles: 0,
    };
  },
  methods: {
    async doFilter() {
      try {
        const data = await fetchApi("v1/vehicles", "GET");
        this.paragraph42Vehicles = data.filter((e) =>
          e.labels.includes("§ 42")
        );
        this.otherVehicles = data.filter((e) => !e.labels.includes("§ 42"));
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    formatDateTime(dateAsString: string) {
      return new Date(dateAsString).toLocaleString("de", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  async created() {
    await this.doFilter();
    this.isOperator = this.$store.state.roles.includes("OPERATOR");

    const data = await fetchApi(
      encodeURI("v1/settings/app-settings/MAX_ACTIVE_PARAGRAPH_42_VEHICLES"),
      "GET"
    );
    this.max42Vehicles = data.value;
  },
});
