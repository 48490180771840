<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      fixed="top"
      style="text-align: center"
    >
      <b-navbar-brand href="#">MoD-Dashboard</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-button
            v-if="
              $store.state.role === 'ADMIN' ||
              $store.state.roles.includes('OPERATOR')
            "
            href=""
            @click="navigator('PassengerBooking')"
            :active="this.currentRoute === 'PassengerBooking'"
            class="d-md-none d-lg-none d-xl-none btn-navbar-action"
            variant="primary"
          >
            Personenfahrt buchen
          </b-button>
          <b-button
            href=""
            @click="navigator('LogisticsBooking')"
            :active="this.currentRoute === 'LogisticsBooking'"
            class="d-md-none d-lg-none d-xl-none btn-navbar-action"
            variant="primary"
          >
            Lieferfahrt buchen
          </b-button>
          <b-nav-item-dropdown
            text="Die Pfalz bringt's"
            v-if="
              $store.state.role === 'ADMIN' ||
              $store.state.roles.includes('OPERATOR')
            "
            href=""
            :active="this.currentRoute === 'DiePfalzBringts'"
            class="d-md-none d-lg-none d-xl-none"
          >
            <b-dropdown-group id="dpb-dd-group-1" header="Online-Shop">
              <b-dropdown-item-button @click="navigator('Orders')"
                >Bestellungen</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('WarehousingOverview')"
                >Warenwirtschaft</b-dropdown-item-button
              >
            </b-dropdown-group>
            <b-dropdown-group id="dpb-dd-group-2" header="Rubriken">
              <b-dropdown-item-button @click="navigator('VendorOverview')"
                >Lieferanten</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('ProductOverview')"
                >Produkte</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('CategoryOverview')"
                >Kategorien</b-dropdown-item-button
              >
            </b-dropdown-group>
            <b-dropdown-group id="dpb-dd-group-3" header="Administration">
              <b-dropdown-item-button @click="navigator('Storefront')"
                >Storefront</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('SettingsDPB')"
                >Einstellungen</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('DPBRegion')"
                >Service Area</b-dropdown-item-button
              >
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Mobility-on-Demand"
            v-if="
              $store.state.role === 'ADMIN' ||
              $store.state.roles.includes('OPERATOR')
            "
            href=""
            @click="navigator('MobilityOnDemand')"
            :active="this.currentRoute === 'MobilityOnDemand'"
            class="d-md-none d-lg-none d-xl-none"
          >
            <b-dropdown-group id="mod-dd-group-1" header="Service">
              <b-dropdown-item-button
                @click="navigator('LogisticsBookingsOverview')"
                >Logisitk-Fahrten</b-dropdown-item-button
              >
              <b-dropdown-item-button
                @click="navigator('PassengersBookingsOverview')"
                >Personen-Fahrten</b-dropdown-item-button
              >
            </b-dropdown-group>
            <b-dropdown-group id="mod-dd-group-2" header="Administration">
              <b-dropdown-item-button @click="navigator('Users')"
                >Nutzer</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('VouchersOverview')"
                >Gutscheine</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('ServiceHours')"
                >Servicezeiten</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('MoDstops')"
                >MoDStops</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('MoDRegion')"
                >Service Area</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('Vehicles')"
                >Fahrzeuge</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="navigator('SettingsMoD')"
                >Einstellungen</b-dropdown-item-button
              >
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Shop verwalten"
            v-if="$store.state.role === 'VENDOR'"
            href=""
            @click="navigator('Orders')"
            :active="this.currentRoute === 'DiePfalzBringts'"
            class="d-md-none d-lg-none d-xl-none"
          >
            <b-dropdown-group id="dpb-vendor-dd-group-1" header="Online-Shop">
              <b-dropdown-item-button @click="navigator('Orders')"
                >Bestellungen</b-dropdown-item-button
              >
              <b-dropdown-item-button
                @click="navigator('LogisticsBookingsOverview')"
                >Fahrten</b-dropdown-item-button
              >
            </b-dropdown-group>
            <b-dropdown-group
              id="dpb-vendor-dd-group-2"
              header="Administration"
            >
              <b-dropdown-item-button
                @click="navigator('VendorDetails', $store.state.roleId)"
                >Unternehmen</b-dropdown-item-button
              >
              <b-dropdown-item-button
                @click="navigator('VendorProducts', $store.state.roleId)"
                >Produkte</b-dropdown-item-button
              >
            </b-dropdown-group>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item right href="">
            <div class="when-open">
              <b-avatar
                size="sm"
                style="margin-right: 15px"
                v-b-modal.userInfoModal
              ></b-avatar>
              <b-modal id="userInfoModal" title="Hallo!" ok-only>
                <p class="my-4">User: {{ $store.state.name }}</p>
                <p class="my-4">Rolle: {{ $store.state.role }}</p>
                <p class="my-4">Rollen: {{ $store.state.roles }}</p>
              </b-modal>
            </div>
          </b-nav-item>
          <b-nav-item right href="" @click="logout()">
            <LogOutIcon /> Logout
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { LogOutIcon } from "vue-feather-icons";
import router from "@/router/index";
import AdminDPB from "@/components/NavbarCollapse/AdminDPB";
import AdminMoD from "@/components/NavbarCollapse/AdminMoD";
import LRide from "@/components/NavbarCollapse/LRide";
import PRide from "@/components/NavbarCollapse/PRide";

export default {
  components: {
    LogOutIcon,
    AdminDPB,
    AdminMoD,
    LRide,
    PRide,
  },
  data() {
    return {
      currentRoute: null,
    };
  },
  methods: {
    navigator: function (page, id) {
      router.push({ name: page, params: { id: id } });
      this.currentRoute = page;
    },
    logout: function () {
      localStorage.removeItem("accessToken");
      router.push({ name: "Login" });
    },
  },
};
</script>
