import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
const VueCookie = require('vue-cookie')
import Vue2Filters from 'vue2-filters'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
//@ts-ignore
import wysiwyg from "vue-wysiwyg"
import VueHtmlToPaper from 'vue-html-to-paper'

import '@/styles/style.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-wysiwyg/dist/vueWysiwyg.css"
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false

Vue.use(wysiwyg, {
  hideModules: {
    "headings": true,
    "link": true,
    "code": true,
    "image": true,
    "table": true,
    locale: 'german'
  },
});

const printOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}


Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookie);
Vue.use(Vue2Filters);
Vue.component("vue-google-autocomplete", VueGoogleAutocomplete);
Vue.use(VueHtmlToPaper, printOptions);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


