




















































import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import store from "@/store";
import { makeStateString } from "@/lib/makeStateString";
import { CheckSquareIcon, ChevronRightIcon } from "vue-feather-icons";
import dayjs from "dayjs";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";

export default Vue.extend({
  name: "MoDstops",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
  },
  data() {
    return {
      getBadgeClassForState,
      role: store.state.role,
      makeStateString,
      page: 1,
      limit: 10,
      loading: true,
      error: null,
      filter: null as any,
      stops: null,
      metaData: null as any,
      isAdmin: false as boolean,
      isOperator: false as boolean,
      fields: [
        { key: "code", label: "Code", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "phase", label: "Phase", sortable: true },
        { key: "area", label: "Bereich", sortable: true },
        { key: "legallyApprovedAfter", label: "§", sortable: true },
        { key: "isPartOfPublicTransport", label: "ÖPNV", sortable: true },
        { key: "isActive", label: "Aktiv", sortable: false },
        { key: "link", label: "" },
      ],
    };
  },
  methods: {
    loadPage(page: number) {
      this.loading = true;
      this.getStops(page, this.limit);
    },
    getStops: async function (page: number = 1, limit: number = 10) {
      fetchApi(`v1/stops`, "GET", null)
        .then((data) => {
          if (data != null) {
            //console.log("STOPS", data);
            this.stops = data;
            this.metaData = data.meta;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    getStopDetails: function (stop: any) {
      console.log("GET STOP", stop.item.id);
      router.push({ name: "MoDstopDetails", params: { id: stop.item.id } });
    },
    filterFunction: function (row: any, filter: string) {
      return row.name.toLowerCase().includes(filter.toLowerCase());
    },
  },
  async created() {
    await this.getStops(1, this.limit);
    this.isAdmin = this.$store.state.role === "ADMIN";
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
