





















































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import VueSelect from "vue-select";
import { generatePassword } from "@/lib/generatePassword";

export default Vue.extend({
  name: "UserAdd",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    timeslotComponent,
    VueSelect,
  },

  data() {
    return {
      vendorId: "",
      loading: true,
      roles: [
        { value: "vendor", label: "Anbieter" },
        { value: "operator", label: "Operator" },
      ],
      selectedRole: { value: "vendor", label: "Anbieter" },
      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      },
      vendors: [],
      selectedVendor: null as any,
      error: null,
    };
  },
  methods: {
    save: function () {
      this.error = "";
      if (this.selectedRole.value === "operator") {
        fetchApi("v1/users/operator", "POST", this.user)
          .then((data) => {
            console.log(data);
            alert("done");
            router.push("UserOverview");
          })
          .catch((e) => {
            console.error("ERR ADR1", e);
            this.error = e;
            alert("error");
            return;
          });
      } else {
        fetchApi(
          "v1/vendors/" + this.selectedVendor.value + "/users",
          "POST",
          this.user
        )
          .then((data) => {
            console.log("DATA", data);
            alert("done");
            router.push("UserOverview");
          })
          .catch((e) => {
            console.error("ERR ADR1", e);
            this.error = e;
            alert("error");
            return;
          });
      }
    },
    setRole: function () {
      if (this.selectedRole.value === "vendor") {
        fetchApi("v1/vendors", "GET", null)
          .then((data) => {
            if (data != null) {
              data.map((v) => {
                this.vendors.push({
                  label: v.name,
                  value: v.id,
                });
              });
            } else {
              throw data["message"];
            }
          })
          .catch((e) => {
            console.error("ERR", e);
            this.error = e;
          });
      }
    },
  },
  async created() {},
});
