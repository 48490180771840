<template>
  <!-- ##########################
         ## Collapse Admin MoD ##
         ######################## -->
  <b-collapse id="sidebarCollapseAdminMoD" class="d-block d-md-none" is-nav>
    <b-navbar-nav>
      <b-nav-text> Service </b-nav-text>
      <b-nav-item to="#" @click="navigator('LogisticsBookingsOverview')">
        Logistik-Fahrten
      </b-nav-item>
      <b-nav-item to="#" @click="navigator('PassengersBookingsOverview')">
        Personen-Fahrten
      </b-nav-item>
      <b-nav-text> Administration </b-nav-text>
      <b-nav-item to="#" @click="navigator('Users')"> Nutzer </b-nav-item>
      <b-nav-item to="#" @click="navigator('VouchersOverview')">
        Gutscheine
      </b-nav-item>
      <b-nav-item to="#" @click="navigator('ServiceHours')">
        Servicezeiten
      </b-nav-item>
      <b-nav-item to="#" @click="navigator('MoDstops')"> MoDstops </b-nav-item>
      <b-nav-item href="#" @click="navigator('SettingsMoD')">
        Einstellungen
      </b-nav-item>
      <hr />
      <b-nav-item href="#" @click="navigator('DiePfalzBringts')">
        Die Pfalz bringt's
      </b-nav-item>
      <b-nav-item href="#" @click="navigator('PassengerBooking')">
        Personenfahrt buchen
      </b-nav-item>
      <b-nav-item href="#" @click="navigator('LogisticsBooking')">
        Lieferfahrt buchen
      </b-nav-item>
    </b-navbar-nav>
  </b-collapse>
</template>

<script>
import router from "@/router/index";

export default {
  data() {
    return {
      currentRoute: null,
    };
  },
  methods: {
    navigator: function (page, id) {
      this.$emit("linkChange", page);
      this.currentRoute = page;
      router.push({ name: page, params: { id: id } });
    },
  },
};
</script>
