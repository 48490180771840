


























































































































































import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import VueSelect from "vue-select";
import router from "@/router";

export default Vue.extend({
  name: "MoDstopDetails",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
  },

  data() {
    return {
      id: this.$route.params.id,
      stop: {
        name: "" as string,
        addressLevel1: "" as string,
        postalCode: "" as string,
        city: "" as string,
        area: "" as string,
        code: "" as string,
        phase: "0" as string,
        isActive: null as boolean,
        latitude: "" as string,
        longitude: "" as string,
        travelTypes: [],
        // delete
        id: "" as string,
        createdAt: "" as string,
        location: [] as any,
        updatedAt: "" as string,
        legallyApprovedAfter: "" as string,
        isPartOfPublicTransport: false as boolean,
        isRestricted: false as boolean,
        nearestVrnStopId: "" as string
      },
      loading: true,
      error: null,
      isOperator: false as boolean,
    };
  },
  methods: {
    getMoDstop: async function () {
      await fetchApi("v1/stops/" + this.id, "GET", null).then(async (data) => {
        if (data != null) {
          this.stop = data;
          this.stop.longitude = data.location.coordinates[0];
          this.stop.latitude = data.location.coordinates[1];
        }
      });
      this.loading = false;
    },
    save: function () {
      const path = `v1/stops/${this.id}`;

      delete this.stop.createdAt;
      delete this.stop.updatedAt;
      delete this.stop.id;
      delete this.stop.location;
      delete this.stop.isRestricted;
      delete this.stop.nearestVrnStopId;

      fetchApi(path, "PATCH", {
        ...this.stop,
        latitude: parseFloat(this.stop.latitude),
        longitude: parseFloat(this.stop.longitude),
        phase: parseInt(this.stop.phase),
      })
        .then((data) => {
          alert("Stop erfolgreich gespeichert.");
        })
        .catch((e) => {
          alert("Speichern des Stops fehlgeschlagen.");
          this.error = e;
          return;
        });
    },
    tagValidator(tag) {
      return ["MoD", "Zug", "SBahn", "NextBike", "Bus"].includes(tag);
    },
  },
  async created() {
    await this.getMoDstop();
    this.isOperator = this.$store.state.roles.includes("OPERATOR");
  },
});
