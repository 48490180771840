<template>
  <!-- ##########################
         ## Collapse Admin DPB ##
         ######################## -->
  <b-collapse
      id="sidebarCollapseAdminMobile"
      class="d-md-none d-lg-none d-xl-none"
      is-nav
  >
    <b-navbar-nav>
      <b-nav-text>Online-Shop</b-nav-text>
      <b-nav-item
          href="#"
          @click="navigator('Orders')"
      >
        Bestellungen
      </b-nav-item>
      <b-nav-text>Rubriken</b-nav-text>
      <b-nav-item
          href="#"
          @click="navigator('VendorOverview')"
      >
        Anbieter
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('ProductOverview')"
      >
        Produkte
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('CategoryOverview')"
      >
        Kategorien
      </b-nav-item>
      <b-nav-text>Administration</b-nav-text>
      <b-nav-item
          href="#"
          @click="navigator('Storefront')"
      >
        Storefront
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('SettingsDPB')"
      >
        Einstellungen
      </b-nav-item>
      <hr>
      <b-nav-item
          href="#"
          @click="navigator('MobilityOnDemand')"
      >
        Mobility-on-Demand
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('PassengerBooking')"
      >
        Personenfahrt buchen
      </b-nav-item>
      <b-nav-item
          href=#""
          @click="navigator('LogisticsBooking')"
      >
        Lieferfahrt buchen
      </b-nav-item>
    </b-navbar-nav>
  </b-collapse>
</template>

<script>
import router from "@/router/index";

export default {
  data() {
    return {
      currentRoute: null
    }
  },
  methods: {
    navigator: function (page, id) {
      this.currentRoute = page;
      router.push({name: page, params: {id: id}});
    }
  }
};
</script>
