

























































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
    ChevronLeftIcon,
    SaveIcon,
    CheckSquareIcon,
    PrinterIcon,
    CheckIcon,
    EyeOffIcon,
} from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import router from "@/router";
import { formatDateFull } from "@/lib/formatDate";

export default Vue.extend({
    name: "ProductList",
    components: {
        ChevronLeftIcon,
        CheckSquareIcon,
        PrinterIcon,
        SaveIcon,
        DPBUpload,
        timeslotComponent,
        QrcodeVue,
        CheckIcon,
        EyeOffIcon,
    },
    data() {
        return {
            //advertisements: null as any,
            error: null,
            fields: [
                { key: "isActiveApp", label: "App", sortable: true },
                { key: "isActiveWeb", label: "Web", sortable: true },
                { key: "name", label: "Name", sortable: true },
                { key: "type", label: "Typ", sortable: true },
                { key: "start", label: "Start", sortable: true },
                { key: "end", label: "Ende", sortable: true },
                { key: "createdAt", label: "Erstellt am", sortable: true },
            ],
            advertisements: [],
            formatDateFull
        };
    },
    methods: {
        getAdvertisements: async function () {
            await fetchApi(
                "v1/advertisement", "GET"
            )
                .then(async (data) => {
                    if (data) {
                        console.log("ad DATA", data);
                        this.advertisements = data;
                    } else {
                        alert('Fehler beim laden der Anzeigen!');
                    }
                })
                .catch((e) => {
                    console.error("ERR", e);
                    alert('Fehler beim laden der Anzeigen!');
                });
        },
        addAdvertisement: function () {
            router.push({ name: "AdvertisementsEditor" });
        },
        getAdvertisementDetails: function (item: { id: string }) {
            console.log("get1: ", item.id);
            router.push({ name: "AdvertisementsEditor", params: { id: item.id } });
        },
    },
    async created() {
        await this.getAdvertisements();
    },
});
