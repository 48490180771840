















































































import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import Vue from "vue";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  name: "Storefront",
  components: {
    ChevronLeftIcon,
    SaveIcon,
  },
  data() {
    return {
      error: "" as string,
      settings: {},
      isOperator: false
    };
  },
  methods: {
    getSettings: async function () {
      const data = await fetchApi(`v1/utils/settings`, "GET");
      let result = {};
      for (const setting of data.filter((s) =>
        s.key.startsWith("STOREFRONT")
      )) {
        result[setting.key] = setting.value;
      }
      this.settings = result;
    },
    save: function () {
      const body = Object.entries(this.settings).map((entry) => {
        return { key: entry[0], value: entry[1] };
      });
      fetchApi(`v1/utils/settings`, "PATCH", body)
        .then((res) => {
          this.settings = res;
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
  async created() {
    await this.getSettings();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
