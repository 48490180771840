






























































import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import { EditIcon, PlusCircleIcon, UploadIcon } from "vue-feather-icons";

export default Vue.extend({
  name: "CategoryOverview",
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: true,
      categories: [],
      categoriesLength: 0,
      filter: null,
      fields: [
        { key: "name", label: "Kategorie" },
        { key: "type", label: "Typ" },
        { key: "to_category", label: "", tdClass: "text-right" },
      ],
      error: null,
      isOperator: false
    };
  },
  methods: {
    getCategories: function () {
      fetchApi("v1/categories", "GET")
        .then((data) => {
          if (data != null) {
            this.categories = data;
            this.categoriesLength = data.length;
            this.loading = false;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    editCategory: function (id: string) {
      router.push({ name: "CategoryDetails", params: { id: id } });
    },
    addCategory: function () {
      router.push({ name: "CategoryAdd" });
    },
  },
  async created() {
    await this.getCategories();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
  components: {
    EditIcon,
    PlusCircleIcon,
    UploadIcon,
  },
  computed: {
    rows(): number {
      return this.categoriesLength;
    },
    sortOptions(): any[] {
      return this.categories
        .filter((f: any) => f.sortable)
        .map((f: any) => {
          return { text: f.label, value: f.key };
        });
    },
  },
});
