



























































import Vue from "vue";
import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import _ from "underscore";

export default Vue.extend({
  name: "SettingsDPB",
  components: {
    ChevronLeftIcon,
    SaveIcon,
  },
  data() {
    return {
      error: "" as string,
      settings: [] as any[],
      initialSettingsFromServer: [] as any[],
      isOperator: false
    };
  },
  methods: {
    getSettings: async function () {
      const data: Array<any> = await fetchApi(`v1/utils/settings`, "GET");
      this.settings = data.filter(
        (s: { settingTargetType: string; key: string }) =>
          s.settingTargetType.startsWith("dpb") &&
          !s.key.startsWith("STOREFRONT")
      );
      this.initialSettingsFromServer = structuredClone(this.settings);
    },
    save: function () {
      //getChangeSet
      const changeset = this.settings.filter((e) => {
        const initialSetting = this.initialSettingsFromServer.find(
          (ie) => ie.key === e.key
        );
        if (!initialSetting) {
          return true;
        }
        return initialSetting.value !== e.value;
      });
      if (changeset.length === 0) {
        alert("no settings to update");
        return;
      }
      const body = this.settings.map((s: { key: any; value: any }) => {
        return { key: s.key, value: s.value };
      });

      fetchApi(`v1/utils/settings`, "PATCH", body)
        .then((res) => {
          this.settings = res.filter(
            (s: { settingTargetType: string; key: string }) =>
              s.settingTargetType.startsWith("dpb") &&
              !s.key.startsWith("STOREFRONT")
          );
          this.initialSettingsFromServer = structuredClone(this.settings);
          alert(`${changeset.length} setttings updated`);
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
  async created() {
    await this.getSettings();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
