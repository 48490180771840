






























































































































import { ChevronLeftIcon, SaveIcon, XIcon } from "vue-feather-icons";
import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import { BSpinner } from "bootstrap-vue";

export default Vue.extend({
  name: "categoryDetails",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    XIcon,
    BSpinner,
  },

  data() {
    return {
      id: this.$route.params.id,
      category: {} as any,
      loading: true,
      error: null,
    };
  },

  methods: {
    back: function () {
      router.push({ name: "CategoryOverview" });
    },

    save: function () {
      delete this.category.id;
      // POST to API
      fetchApi("v1/categories/" + this.id, "PATCH", this.category).then(() => {
        router.push({ name: "CategoryOverview" });
      });
    },

    getCategory: function () {
      fetchApi("v1/categories/" + this.id, "GET")
        .then((data) => {
          this.category = data;
          this.loading = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    deleteCat: function (continueDelete) {
      if (continueDelete) {
        fetchApi(`v1/categories/${this.id}`, "DELETE")
          .then(() => {
            router.push({ name: "CategoryOverview" });
          })
          .catch((e) => {
            console.error("ERR", e);
            this.error = e;
          });
      } else {
        this.$bvModal.show("confirmDelete-Dialog");
      }
    },
  },

  async created() {
    this.getCategory();

    if (!this.id) {
      router.push({ name: "CategoryOverview" });
    }
  },
});
