



























































































import Vue from "vue";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  name: "Driver",
  data() {
    return {
      deliveryRideId: this.$route.query.deliveryRideId as string,
      deliveryRide: null as any,
      driverId: null as any,
      statusInformation: null as any,
      initialStatus: null as any,
      error: null as any,
      items: null as any,
      vendorOrderData: null as any,
      fields: [{ key: "key", tdClass: "firstRow" }, { key: "value" }],
      options: {
        IN_DELIVERY: "In Auslieferung",
        COMPLETED: "Abgeschlossen",
      },
    };
  },
  methods: {
    init: async function () {
      // Get Order and Vendors order to render and get first status
      await fetchApi("v1/delivery-rides/" + this.deliveryRideId, "GET")
        .then(async (data) => {
          if (data != null) {
            console.log(data);
            this.deliveryRide = data;
            this.initialStatus = data.rideStateChanges[0].state;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });

      if (this.initialStatus == "VEHICLE_ASSIGNED") {
        // Automatically receiving ride information, in other states user feedback is necessary
        await this.getStatusInformation();
      } else if (
        this.initialStatus != "IN_DELIVERY" &&
        this.initialStatus != "COMPLETED"
      ) {
        // Invalid Status
        this.error =
          "Fehler: Bestellung befindet sich im Status: " + this.initialStatus;
      }
    },

    getStatusInformation: async function () {
      fetchApi(
        "v1/orders/" + this.deliveryRideId + "/change-state-by-driver",
        "PATCH"
      )
        .then((data) => {
          if (data != null) {
            console.log(data);
            this.statusInformation = data;

            // Creating Timeframe string
            const timeFrame =
              new Date(
                this.statusInformation.ride.stopPoints[1].afterTime
              ).toLocaleDateString("DE-de", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }) +
              " - " +
              new Date(
                this.statusInformation.ride.stopPoints[1].beforeTime
              ).toLocaleDateString("DE-de", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              });

            // Table Rendering
            this.items = [
              {
                key: "Empfängername",
                value:
                  this.statusInformation.ride.stopPoints[1].contactPersonName,
              },
              {
                key: "Lieferadresse",
                value: this.statusInformation.ride.stopPoints[1].description,
              },
              {
                key: "Artikelanzahl",
                value: this.statusInformation.ride.stopPoints[1].numberOfItems,
              },
              { key: "Lieferzeitraum", value: timeFrame },
              {
                key: "Einordnen an Position",
                value: this.statusInformation.positionString,
              },
              {
                key: "Neuer Status",
                value: this.options[this.statusInformation.state],
              },
            ];
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    completeDropoff: async function () {
      // User interaction necessary to complete Dropoff

      fetchApi(
        "v1/orders/" + this.deliveryRideId + "/change-state-by-driver",
        "PATCH"
      )
        .then((data) => {
          if (data != null) {
            this.initialStatus = data;
            this.initialStatus = this.initialStatus.state;

            // No status change? - Should not happen
            if (this.initialStatus === "IN_DELIVERY") {
              // Wrong vehicle
              alert(
                "Falsches Fahrzeug, aber richtiges Fahrzeug beim ersten Scan"
              );
            }
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
  },
  async created() {
    this.driverId = localStorage.getItem("driverId");
    console.log("Driver ID: ", this.driverId);
    if (this.driverId) {
      await this.init();
    } else {
      // No driver ID in local storage
      this.error = "Nicht als Fahrer angemeldet!";
    }
  },
});
