





























































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
  ChevronLeftIcon,
  SaveIcon,
  CheckSquareIcon,
  PrinterIcon,
} from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import store from "@/store";

export default Vue.extend({
  name: "ProductList",
  components: {
    ChevronLeftIcon,
    CheckSquareIcon,
    PrinterIcon,
    SaveIcon,
    DPBUpload,
    timeslotComponent,
    QrcodeVue,
  },
  data() {
    return {
      dayDate: null as string,
      isVendor: true as boolean,
      loading: true as boolean,
      products: null as any,
      sum: null as string,
      vendorId: store.state.roleId,
      fields: [
        { key: "quantity", label: "Anzahl" },
        { key: "name", label: "Produkt" },
        { key: "bruttoPrice", label: "Stückpreis" },
        { key: "partSum", label: "Teilsumme" },
      ],
      items: null as any,
      error: null,
      isPrinting: false as boolean,
      svgString: null as string,
    };
  },
  methods: {
    async print() {
      this.isPrinting = true;
      await this.$htmlToPaper("printArea", null, () => {
        console.log("Printing done");
        this.isPrinting = false;
      });
    },
    back: function () {
      router.push({ name: "DeliveriesOverview" });
    },
    getOrderProducts: async function () {
      // /orders/vendorOrderedProductList/<vendorId>/<2022-04-21>
      console.log("V ID", this.vendorId);
      console.log("DayDate", this.dayDate);
      await fetchApi(
        "v1/orders/vendor-ordered-product-list/" +
          this.vendorId +
          "/" +
          this.dayDate,
        "GET"
      )
        .then(async (data) => {
          if (data != null) {
            console.log("ORDER DATA", data);
            this.products = data;
            this.sum = data.reduce((acc, e) => {
              return acc + e.partSum;
            }, 0);
            console.log("SUM", this.sum);
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
  },
  async created() {
    this.dayDate = this.$route.params.date;
    this.isVendor = this.$store.state.role === "VENDOR";
    this.vendorId = store.state.roleId;
    await this.getOrderProducts();
  },
});
