


















































































import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import store from "@/store";
import { makeStateString } from "@/lib/makeStateString";
import {
  CheckSquareIcon,
  ChevronRightIcon,
  PlusCircleIcon,
} from "vue-feather-icons";
import dayjs from "dayjs";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";

export default Vue.extend({
  name: "Users",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
    PlusCircleIcon,
  },
  data() {
    return {
      getBadgeClassForState,
      role: store.state.role,
      makeStateString,
      page: 1,
      limit: 10,
      loading: true,
      error: null,
      filter: null as any,
      users: null,
      metaData: null as any,
      isAdmin: false as boolean,
      isOperator: false as boolean,
      fields: [
        { key: "firstName", label: "Vorname", sortable: false },
        { key: "lastName", label: "Nachname", sortable: true },
        { key: "isActive", label: "Aktiv", sortable: true },
        { key: "balance", label: "Guthaben", sortable: false },
        { key: "phoneNumber", label: "Telefon", sortable: false },
        { key: "roles", label: "Rollen", sortable: false },
        { key: "link", label: "" },
      ],
    };
  },
  watch: {
    // whenever question changes, this function will run
    filter(newFilter, oldFilter) {
      if (newFilter !== "" && newFilter !== null && newFilter !== undefined) {
        this.filterFunc(newFilter);
      } else {
        this.getUsers();
      }
    },
  },
  methods: {
    loadPage(page: number) {
      this.loading = true;
      this.getUsers(page, this.limit);
    },
    getUsers: async function (page: number = 1, limit: number = 10) {
      let queryUrl = `v1/users?page=${page}&limit=${limit}`;
      if (this.filter != null && this.filter != '') {
        queryUrl += `&filterByLastName=${this.filter}`;
      }
      fetchApi(queryUrl, "GET", null)
        .then((data) => {
          if (data != null) {
            this.users = data.items;
            this.metaData = data.meta;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    getUserDetails: function (user: { id: string }) {
      router.push({ name: "UserDetails", params: { id: user.id } });
    },
    filterFunc: function (filter: string) {
      fetchApi(
        `v1/users?page=1&limit=10&filterByLastName=${filter}`,
        "GET",
        null
      )
        .then((data) => {
          //console.log("USERS", data);
          this.users = data.items;
          this.metaData = data.meta;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    makeRoleString: function (string: string) {
      return string === "VENDOR"
        ? "Händler"
        : string === "DRIVER"
          ? "Fahrer"
          : string === "ADMIN"
            ? "Admin"
            : string === "OPERATOR"
              ? "OPERATOR"
              : "Kunde";
    },
    addCustomerUser: function () {
      router.push({ name: "UserCustomerAdd" });
    },
    addUser: function () {
      router.push({ name: "UserAdd" });
    },
  },
  async created() {
    await this.getUsers(1, this.limit);
    this.isAdmin = this.$store.state.role === "ADMIN";
    this.isOperator = this.$store.state.roles.includes("OPERATOR");
  },
});
