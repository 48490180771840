








import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import SidebarMain from "@/components/SidebarMain.vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

export default Vue.extend({
  components: {
    Navbar,
    SidebarMain,
  }
});

Vue.use(BootstrapVue);
