export default [
    {
        "value": "misc",
        "text": "Sonstiges/Keine Kostenstelle"
    },
    {
        "value": "stripe",
        "text": "Manuelle Aufladung Kreditkarte (Stripe)"
    },
    {
        "value": "paypal",
        "text": "Manuelle Aufladung PayPal"
    },
    {
        "value": "wise",
        "text": "Manuelle Aufladung Überweisung (Wise/TransferWise)"
    },
    {
        "value": "qonto",
        "text": "Manuelle Aufladung Überweisung (Qonto)"
    },
    {
        "value": "mod",
        "text": "Fehlbuchung/Stornierung MoD"
    },
    {
        "value": "dpb",
        "text": "Fehlbuchung/Stornierung DPB"
    },
    {
        "value": "4701",
        "text": "4701 MoD Dienstfahrten"
    },
    {
        "value": "4703",
        "text": "4703 MoDcar Dienstfahrten"
    },
    {
        "value": "4704",
        "text": "4704 MoDbike Dienstfahrten"
    },
    {
        "value": "4711",
        "text": "4711 DPB-L Dienstfahrten"
    },
    {
        "value": "4712",
        "text": "4712 MoD-L Dienstfahrten"
    },
    {
        "value": "4720",
        "text": "4720 Solutions Testfahrten (z.B. HitchhikeBox)"
    },
    {
        "value": "4741",
        "text": "4741 DPB Dienstfahrten (z.B. Einkäufe)"
    },
    {
        "value": "4140",
        "text": "4140 Freiwillige soziale Aufwendungen, lohnsteuerfrei (50 € Mitarbeitergutschein)"
    },
    {
        "value": "4600",
        "text": "4600 Werbekosten MoD Holding GmbH (Schied, Brexius)"
    },
    {
        "value": "4601",
        "text": "4601 Werbekosten MoD (z.B. Weinköniginnen, 1. FC. Hambach)"
    },
    {
        "value": "4602",
        "text": "4602 Werbekosten DPB (z.B. Haardter Feierabendmarkt)"
    },
    {
        "value": "4663",
        "text": "4663 Dienstfahrten+Reisekosten (z.B. Fahrten zu Besprechungen, Fahrten zum Hbf. bei Dienstreisen)"
    }
];