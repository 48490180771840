export function processStringToMoney(money: string) {
    const moneyString = parseInt(money) / 100
    return moneyString.toFixed(2);
}
export function processMoneyToString(money: number) {
    money = Math.round(money * 100)
    return money | 0;
}


