export function accessTokenPayload(accessToken: string | null) {

    if (accessToken !== null) {
        try {
            const decoded = atob(accessToken.split('.')[1])

            return JSON.parse(decoded)
        } catch {
            return null
        }
    } else {
        return null
    }
}

export function isAccessTokenValid(payload: any) {
    if (payload === null) {
        return false
    } else {
        if (payload.hasOwnProperty('exp')) {
            const now = (Date.now() / 1000)
            return payload.exp >= now
        }
        return false
    }
}
