









































































import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import { BSpinner } from "bootstrap-vue";
import { processStringToMoney } from "@/lib/processMoneyString";
import { EditIcon, PlusCircleIcon } from "vue-feather-icons";

export default Vue.extend({
  name: "productOverview",
  components: {
    BSpinner,
    EditIcon,
    PlusCircleIcon,
  },

  data() {
    return {
      productsData: {} as any,
      page: 1,
      limit: 10,
      loading: true,
      error: null,
      filter: null as any,
      fields: [
        { key: "name", label: "Produkt", sortable: true },
        { key: "shortDescription", label: "Kurz Beschreibung" },
        { key: "vendor", label: "Händler" },
        { key: "bruttoPrice", label: "Preis" },
        { key: "isActive", label: "Aktiv" },
        { key: "to_product", label: "" },
      ],
      isOperator: false
    };
  },
  watch: {
    // whenever question changes, this function will run
    filter(newFilter, oldFilter) {
      if (newFilter !== "" && newFilter !== null && newFilter !== undefined) {
        this.filterFunc(newFilter);
      } else {
        this.getProducts();
      }
    },
  },

  methods: {
    loadPage(page: number) {
      this.loading = true;
      this.getProducts(page, this.limit);
    },

    getProducts: function (page: number = 1, limit: number = 10) {
      this.loading = true;
      fetchApi(`v1/products?page=${page}&${limit}`, "GET")
        .then(async (data) => {
          this.productsData = (await data) as any;
          for (let i = 0; i < this.productsData.meta.itemCount; i++) {
            let product = this.productsData.items[i] as any;
            product.bruttoPrice = processStringToMoney(
              product.bruttoPrice as string
            );
          }
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
          this.error = e;
          this.loading = false;
        });
    },
    filterFunc: function (filter: string) {
      this.loading = true;
      fetchApi(
        `v1/products?page=1&limit=10&filterByName=${filter}`,
        "GET",
        null
      )
        .then((data) => {
          console.log("PRODUCTS", data);
          this.productsData = data;
          for (let i = 0; i < this.productsData.meta.itemCount; i++) {
            let product = this.productsData.items[i] as any;
            product.bruttoPrice = processStringToMoney(
              product.bruttoPrice as string
            );
          }
          this.loading = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          this.loading = false;
        });
    },

    editProduct: function (id: string) {
      router.push({ name: "ProductDetails", params: { id: id } });
    },

    addProduct: function () {
      router.push({ name: "ProductAdd" });
    },

    /*filterFunc: function (row: any, filter: string) {
      return (
        row.name.toLowerCase().includes(filter.toLowerCase()) ||
        row.vendor.name.toLowerCase().includes(filter.toLowerCase())
      );
    },*/
  },
  async created() {
    this.getProducts(1, 10);
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
