
















































import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { fetchApi } from "@/lib/fetch";
import { accessTokenPayload, isAccessTokenValid } from "@/lib/validAccessToken";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: null,
      SERVER_HOST: process.env.VUE_APP_SERVER_HOST,
    };
  },
  methods: {
    login: async function () {
      // Get data from UI
      const LoginData = {
        username: this.username,
        password: this.password,
      };
      // Post to API
      fetchApi("v1/auth/login", "POST", LoginData)
        .then((data) => {
          if (data["accessToken"]) {
            localStorage.setItem("accessToken", data["accessToken"]);
            localStorage.setItem("userData", JSON.stringify(data));
            store.dispatch("setUserdata", data);
            if (data["userId"]) {
              localStorage.setItem("driverId", data["userId"]);
            }
            router.push({ name: "Dashboard" });
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
  },

  async mounted() {
    let payload = accessTokenPayload(localStorage.getItem("accessToken"));
    let isAuthenticated = isAccessTokenValid(payload);
    if (isAuthenticated) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        await store.dispatch("setUserdata", userData);
      } else {
        await store.dispatch("setUserdata", payload);
      }
      if (payload["userId"]) {
        localStorage.setItem("driverId", payload["userId"]);
      }
      console.log("Push Initiated");
      await router.push({ name: "Dashboard" });
    }
  },
});
