import { render, staticRenderFns } from "./TransactionsOverview.vue?vue&type=template&id=528f6c36&xmlns%3Abottom=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./TransactionsOverview.vue?vue&type=script&lang=ts&"
export * from "./TransactionsOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports