


















































































































































































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
  ChevronLeftIcon,
  SaveIcon,
  CheckSquareIcon,
  PrinterIcon,
  CopyIcon,
} from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import { makeStateString } from "@/lib/makeStateString";
import { getBadgeClassForState, isSelfPickupOverdue, calculateDayDiff } from "@/lib/uiStatesUtil";
import VueSelect from "vue-select";
import { min } from "underscore";

export default Vue.extend({
  name: "OrderDetails",
  components: {
    ChevronLeftIcon,
    CheckSquareIcon,
    PrinterIcon,
    CopyIcon,
    SaveIcon,
    DPBUpload,
    timeslotComponent,
    QrcodeVue,
    VueSelect,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1)
      // 15th two months prior
    const minDate = new Date(today)
    return {
      minDate: minDate,
      makeStateString,
      getBadgeClassForState,
      isSelfPickupOverdue,
      calculateDayDiff,
      id: this.$route.params.id,
      url: "http://" + window.location.host,
      isVendor: true as boolean,
      isAdmin: false as boolean,
      isOperator: false as boolean,
      loading: true as boolean,
      order: null as any,
      fields: [
        { key: "product", label: "Produkt" },
        { key: "amount", label: "Anzahl" },
        { key: "bruttoPrice", label: "Stückpreis brutto" },
        { key: "taxRate", label: "Steuersatz" },
        { key: "partSum", label: "Teilsumme" },
      ],
      items: null as any,
      options: [
        { value: "ACCEPTED", text: "Angenommen", disabled: true },
        { value: "PENDING", text: "Wartend", disabled: true },
        { value: "PREPARED", text: "Vorbereitet" },
        { value: "VEHICLE_ASSIGNED", text: "Zugewiesen" },
        { value: "IN_DELIVERY", text: "In Auslieferung" },
        { value: "COMPLETED", text: "Abgeschlossen" },
        { value: "REJECTED", text: "Abgelehnt", disabled: true },
        { value: "CANCELLED", text: "Abgebrochen" },
      ],
      optionsPickup: [
        { value: "SELF_PICKUP", text: "Selbstabholung" },
        { value: "PICKED_UP", text: "Abgeholt" },
        { value: "OVERDUE", text: "Überfällig" },
        { value: "CANCELLED", text: "Abgebrochen" },
      ],
      error: null,
      isPrinting: false as boolean,
      svgString: null as string,
      orderIsSelfPickup: false as boolean,
      selectedDayForCloneOrder: null as Date,
    };
  },
  methods: {
    async getSVG(vendorOrderId) {
      return fetch(
        process.env.VUE_APP_SERVER_HOST +
          "/v1/orders/" +
          this.id +
          "/vendor-order/" +
          vendorOrderId +
          "/receipt-svg",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      )
        .then((r) => r.text())
        .then((data) => {
          console.log("svgString", data);
          return Promise.resolve(data);
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          return Promise.reject(e);
        });
    },
    async print(vendorOrderId) {
      this.isPrinting = true;
      const svgString = await this.getSVG(vendorOrderId);
      this.svgString = svgString;
      this.$nextTick(async () => {
        await this.$htmlToPaper("printArea", null, () => {
          console.log("Printing done");
          this.isPrinting = false;
        });
      });
    },
    back: function () {
      router.push({ name: "DeliveriesOverview" });
    },
    getDelivery: async function () {
      await fetchApi("v1/orders/" + this.id, "GET")
        .then(async (data) => {
          if (data != null) {
            //console.log("ORDER DATA", data);
            this.order = data;
            this.orderIsSelfPickup = this.order.vendorOrders[0].isClickAndCollect;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    setOrderState: function (
      event: any,
      vendorId: string,
    ) {
      //console.log(event);
      if (event == "PREPARED") {
        this.print(vendorId).then(() => {});
      }

      fetchApi(
        "v1/orders/" +
          this.id +
          "/vendor-order/" +
          vendorId +
          "/change-state",
        "PATCH",
        { rideState: event }
      )
        .then((data) => {
          //console.log("DATA: ",data);
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          return;
        });
    },
    getRideDetails: function (id: string) {
      router.push({ name: "RideDetails", params: { id: id } });
    },
    cloneOrder: function (vendorOrderId: string, newDate: Date) {
      fetchApi(
        "v1/orders/vendor-order/" +
          vendorOrderId +
          "/clone/" + 
          newDate,
        "POST"
      )
        .then((data) => {
          //console.log("DATA: ",data);
          alert("Order erfolgreich geklont.");
        })
        .catch((e) => {
          alert("Klonen der Order fehlgeschlagen.");
          console.error("ERR", e);
          this.error = e;
          return;
        });
    },
  },

  async created() {
    await this.getDelivery();
    this.isVendor = this.$store.state.role === "VENDOR";
    this.isAdmin = this.$store.state.role === "ADMIN";
    this.isOperator = this.$store.state.role === "OPERATOR";
  },
});
