

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class DPBUpload extends Vue {
  @Prop() imageUri?: string;

  placeholderImage: string =
    "https://storage.googleapis.com/diepfalzbringts/vendors/9f51eed2-6357-4aff-8ad5-c28b00351d58/products/00926ab1-9b78-4e56-b327-5120f1647eab/thumbnail.00926ab1-9b78-4e56-b327-5120f1647eab.raw.jpg";

  currentImageUri: string = "";
  uploadedFileId: string = "";
  file: File | null = null;

  mounted() {
    this.currentImageUri = this.imageUri!;
  }

  get displayImgUri() {
    if (this.currentImageUri) {
      return this.currentImageUri;
    }
    // Avoid Image caching by adding random number
    return this.placeholderImage + '?' + Math.random();
  }

  @Watch("file")
  onWatchFile(newValue: File, oldValue: File) {
    if (newValue !== null && newValue instanceof File) {
      this.currentImageUri = URL.createObjectURL(newValue);
    }
  }

  async onDelete() {
    // Avoid Image caching by adding random number
    this.currentImageUri = this.placeholderImage + '?' + Math.random();
    this.$emit("on-file-id", null);
  }

  async onUpload() {
    if (!this.file) {
      alert("no file provided");
      return;
    }
    console.log(this.file);
    let formData = new FormData();
    formData.append("file", this.file);
    try {
      const resp = await fetch(`${process.env.VUE_APP_SERVER_HOST}/v1/upload`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: formData,
      });
      if (resp.ok) {
        const body = await resp.json();
        this.uploadedFileId = body.fileId;
        this.$emit("on-file-id", body.fileId);
      } else {
        console.error(resp.status);
        alert(resp.status);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }
}
