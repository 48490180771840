<template>
  <div>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-4 pt-5">
      <b-alert show v-if="error" variant="danger" class="mt-2">{{
        error
      }}</b-alert>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-2">
        <b-link @click="$router.go(-1)">
          <ChevronLeftIcon /> Zurück
        </b-link>
      </div>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-3">
        <h1 class="h2">Produkt bearbeiten</h1>
      </div>
      <div class="flex-1 relative p-8 pb-24" v-if="!this.loading.category && !this.loading.product">
        <div class="p-4">
          <b-form @submit="save">
            <div class="form-group mb-3">
              <label class="mb-2">Händler</label>
              <vue-select type="text" placeholder="Händler" v-model="this.product.vendor.name"
                :options="[this.product.vendor]" label="name" disabled />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Name <span class="required">*</span></label>
              <input class="form-control" type="text" placeholder="Name" v-model="product.name" />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Kurz Beschreibung</label>
              <input class="form-control" type="text" placeholder="Kurze Beschreibung"
                v-model="product.shortDescription" />
              <!--<wysiwyg
                class="form-control"
                placeholder="Kurz Beschreibung"
                v-model="product.shortDescription"
              />-->
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Beschreibung</label>
              <b-form-textarea id="textareaDesc" v-model="product.description" placeholder="Beschreibung" rows="3"
                max-rows="6"></b-form-textarea>
              <!--<wysiwyg
                class="form-control"
                placeholder="Beschreibung"
                v-model="product.description"
              />-->
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Länge</label>
              <input class="form-control" type="number" placeholder="Länge" v-model="product.length" />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Breite</label>
              <input class="form-control" type="number" placeholder="Breite" v-model="product.width" />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Höhe</label>
              <input class="form-control" type="number" placeholder="Höhe" v-model="product.height" />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Gewicht</label>
              <input class="form-control" type="number" placeholder="Gewicht" v-model="product.weight" />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Brutto Preis <span class="required">*</span></label>
              <input class="form-control" type="number" placeholder="Brutto Preis" v-model="product.bruttoPrice" />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Steuersatz <span class="required">*</span></label>
              <input class="form-control" type="number" placeholder="Steuersatz" v-model="product.taxRate" />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Pack Größe <span class="required">*</span></label>
              <input class="form-control" type="number" placeholder="Pack Größe" v-model="product.packSize" />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Packeinheit <span class="required">*</span></label>
              <input class="form-control" type="text" placeholder="Packeinheit" v-model="product.packSizeUnit" />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Faktor Liefereinheit (0.00)<span class="required">*</span></label>
              <input class="form-control" type="text" placeholder="Faktor Liefereinheit"
                v-model="product.deliveryFeeFactor" />
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Produkt EAN</label>
              <input class="form-control" type="text" placeholder="z. B. 5901234123457" v-model="product.eanNumber" />
            </div>

            <div class="form-check mb-3">
              <label class="mb-2 me-2">Aktiv? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.isActive" />
            </div>
            <div class="form-check mb-3">
              <label class="mb-2 me-2">Altersbeschränkung? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.isAgeRestricted" />
            </div>
            <div class="form-check mb-3">
              <label class="mb-2 me-2">Highlighted? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.isHighlighted" />
            </div>
            <div class="form-check mb-3">
              <label class="mb-2 me-2">Highlighted im StartScreen der App? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.isFeaturedStartScreen" />
            </div>
            <div class="form-check mb-3">
              <label class="mb-2 me-2">Kühlung notwendig? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.requiresCooling" />
            </div>
            <div class="form-check mb-5">
              <label class="mb-2 me-2">Verfügbar? <span class="required">*</span></label>
              <input class="form-check-input ml-2" type="checkbox" v-model="product.isAvailable" />
            </div>
            <div class="categories mb-5">
              <p class="h4">Warenwirtschaft</p>
              <hr />
              <b-row v-if="product.vendor.warehousingEnabled">
                <div class="form-group mb-5">
                  <label class="mb-2 me-2">Lagerbestand <span class="required">*</span></label>
                  <input class="form-control" type="number" placeholder="Menge" v-model="product.storeQuantity" />
                </div>
                <div class="form-group mb-5">
                  <label class="mb-2 me-2">Mindestbestand für Warnungs-Email<span class="required">*</span></label>
                  <input class="form-control" type="number" placeholder="Menge" v-model="product.qtyAlertThreshold" />
                </div>
                <div class="form-group mb-5">
                  <label class="mb-2 me-2">Empfohlener Bestand <span class="required">*</span></label>
                  <input class="form-control" type="number" placeholder="Menge" v-model="product.recommendedQuantity" />
                </div>
              </b-row>
            </div>

            <div class="categories mb-5">
              <p class="h4">Kategorien</p>
              <hr />
              <ul>
                <div class="mb-2 row" v-if="addingCategory">
                  <vue-select :options="categories" label="name" v-model="category" class="col-10 h-100" />
                  <button class="btn btn-outline-secondary col-2 h-100" v-on:click.prevent="appendCategory">
                    <check-icon></check-icon>
                  </button>
                </div>

                <li class="mb-2" v-for="category in this.product.categories">
                  <div class="row">
                    <p class="d-inline col-10">{{ category.name }}</p>
                    <button class="d-inline col-2 btn btn-sm btn-outline-danger" v-on:click.prevent="() => {
                      removeCategory(category.id);
                    }
                      ">
                      <x-icon></x-icon>
                    </button>
                  </div>
                </li>
              </ul>
              <button class="btn btn-outline-secondary btn-sm rounded" v-on:click.prevent="addCategory">
                Kategorie hinzufügen
              </button>
            </div>

            <div class="attributes mb-5">
              <p class="h4">Attribute</p>
              <hr />
              <ul>
                <div class="mb-2 row form-group" v-if="addingAttribute">
                  <input type="text" v-model="attribute.name" class="col-5 h-100 form-control" style="width: 41.666666%"
                    placeholder="Name" />
                  <input type="text" v-model="attribute.value" class="col-5 h-100 form-control" style="width: 41.666666%"
                    placeholder="Wert" />
                  <button class="btn btn-outline-secondary col-2 h-100" v-on:click.prevent="appendAttribute">
                    <check-icon></check-icon>
                  </button>
                </div>

                <li class="mb-2" v-for="attribute in this.attributes">
                  <div class="row">
                    <p class="d-inline col-5">{{ attribute.name }}</p>
                    <p class="d-inline col-5">{{ attribute.value }}</p>
                    <button class="d-inline col-2 btn btn-sm btn-outline-danger" v-on:click.prevent="() => {
                      removeAttribute(attribute);
                    }
                      ">
                      <x-icon></x-icon>
                    </button>
                  </div>
                </li>
              </ul>
              <button class="btn btn-outline-secondary btn-sm rounded" v-on:click.prevent="addAttribute">
                Attribut hinzufügen
              </button>
            </div>

            <div class="form-group mb-3">
              <label class="mb-2">Produktbild</label>
              <div class="input-group mb-3">
                <DPBUpload :imageUri="imageUri" v-on:on-file-id="onImageId" />
              </div>
            </div>

            <div class="form-group mb-5">
              <label class="mb-2">Vorschaubild</label>

              <div class="input-group mb-3">
                <DPBUpload :imageUri="thumbnailUri" v-on:on-file-id="onThumbnailId" />
              </div>
            </div>

            <div class="mb-5">
              <p class="h4">Nicht verfügbar</p>
              <b-form-group label="Produkt ist an folgenden Werk- und Feiertagen nicht verfügbar:"
                v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group-1" v-model="product.excludedWeekdays"
                  :options="notAvailableOptions" :aria-describedby="ariaDescribedby"
                  name="flavour-1"></b-form-checkbox-group>
              </b-form-group>
            </div>
            <b-alert show v-if="error" variant="danger">{{ error }}</b-alert>

            <b-btn class="btn-dpb rounded" v-on:click.prevent="save">
              <SaveIcon /> Speichern
            </b-btn>
            <b-btn variant="outline-danger " class="rounded mx-2" v-on:click.prevent="deleteProd(false)">
              <XIcon /> Löschen
            </b-btn>

            <b-modal id="confirmDelete-Dialog" title="Löschung bestätigen" v-on:ok="() => {
              deleteProd(true);
            }
              " ok-variant="danger" ok-title="Löschen" cancel-variant="outline-secondary" cancel-title="Abbrechen">
              <p class="mt-4 mb-1">
                Sind Sie sicher, dass Sie das Produkt "{{ product.name }}"
                löschen möchten?
              </p>
              <br />
            </b-modal>
          </b-form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ChevronLeftIcon, SaveIcon, XIcon, CheckIcon } from "vue-feather-icons";
import VueSelect from "vue-select";
import timeslotComponent from "@/components/TimeslotComponent.vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import { BSpinner } from "bootstrap-vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
  processStringToMoney,
  processMoneyToString,
} from "@/lib/processMoneyString";

export default Vue.extend({
  name: "productDetails",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
    XIcon,
    CheckIcon,
    timeslotComponent,
    BSpinner,
    DPBUpload,
  },
  data() {
    return {
      loading: {
        category: true,
        product: true,
        //timeslots: false,
        //attributes: false,
      },
      id: this.$route.params.id,
      notAvailableOptions: [
        { text: "Montag", value: "MONDAY" },
        { text: "Dienstag", value: "TUESDAY" },
        { text: "Mittwoch", value: "WEDNESDAY" },
        { text: "Donnerstag", value: "THURSDAY" },
        { text: "Freitag", value: "FRIDAY" },
        { text: "Samstag", value: "SATURDAY" },
        { text: "Sonntag", value: "SUNDAY" },
        { text: "alle Feiertage in RLP", value: "HOLIDAY" },
      ],
      product: {
        vendor: {
          name: "",
        },
      },
      category: {},
      attribute: {},
      addingCategory: false,
      addingAttribute: false,
      categories: [],
      attributes: [],
      deliveryTimeslots: [],
      imageUri: null,
      imageId: undefined,
      thumbnailUri: null,
      thumbnailId: undefined,
      error: null,
      deliveryFeeFactor: 0,
    };
  },
  methods: {
    save: async function () {
      // Get new timeslots
      const newTimeslots = this.deliveryTimeslots.filter((el) => el.id == null);

      for (const slot of newTimeslots) {
        console.log("New Timeslot", slot);
        await fetchApi(
          `v1/products/${this.id}/delivery-time-slots`,
          "POST",
          slot
        ).catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
      }

      // DELETE Timeslots
      this.product.deliveryTimeSlots.map(async (slot, index) => {
        let deleted = true;
        this.deliveryTimeslots.map((delSlot) => {
          if (slot.id === delSlot.id) {
            deleted = false;
          }
        });
        if (deleted) {
          await fetchApi(
            `v1/products/${this.id}/delivery-time-slots/${slot.id}`,
            "DELETE"
          ).catch((e) => {
            console.error("ERR", e);
            this.error = e;
          });
        }
      });

      // Get new attributes
      const newAttributes = this.attributes.filter((el) => el.id == null);

      newAttributes.map(async (attr) => {
        await fetchApi(`v1/products/${this.id}/attributes`, "POST", attr).catch(
          (e) => {
            console.error("ERR", e);
            this.error = e;
          }
        );
      });

      // DELETE Attributes
      this.product.attributes.map(async (attribute) => {
        let deleted = true;

        this.attributes.map((attr) => {
          if (attribute.id === attr.id) {
            deleted = false;
          }
        });

        if (deleted) {
          await fetchApi(
            `v1/products/${this.id}/attributes/${attribute.id}`,
            "DELETE"
          ).catch((e) => {
            console.error("ERR", e);
            this.error = e;
          });
        }
      });

      // Patch Categories
      const newCategories = this.product.categories;
      await fetchApi(
        `v1/products/${this.id}/categories`,
        "PATCH",
        newCategories
      ).catch((e) => {
        console.error("ERR", e);
        this.error = e;
      });

      const updateObject = {
        ...this.product,
      };
      if (updateObject.length) {
        updateObject.length = parseFloat(updateObject.length);
      }
      if (updateObject.width) {
        updateObject.width = parseFloat(updateObject.width);
      }
      if (updateObject.height) {
        updateObject.height = parseFloat(updateObject.height);
      }
      if (updateObject.weight) {
        updateObject.weight = parseFloat(updateObject.weight);
      }
      if (updateObject.deliveryFeeFactor) {
        updateObject.deliveryFeeFactor = parseFloat(
          updateObject.deliveryFeeFactor
        );
      }
      if (updateObject.taxRate) {
        updateObject.taxRate = parseFloat(updateObject.taxRate);
      }
      if (updateObject.packSize) {
        updateObject.packSize = parseFloat(updateObject.packSize);
      }

      if (updateObject.storeQuantity) {
        updateObject.storeQuantity = parseInt(updateObject.storeQuantity);
      }

      if (updateObject.qtyAlertThreshold) {
        updateObject.qtyAlertThreshold = parseInt(updateObject.qtyAlertThreshold);
      }

      if (updateObject.recommendedQuantity) {
        updateObject.recommendedQuantity = parseInt(updateObject.recommendedQuantity);
      }

      delete updateObject.id;
      delete updateObject.createdAt;
      delete updateObject.updatedAt;
      delete updateObject.deliveryTimeSlots;
      delete updateObject.vendor;
      delete updateObject.categories;
      delete updateObject.attributes;
      delete updateObject.deletedAt;

      if (this.imageId === undefined) {
        delete updateObject.productImageUri;
      } else {
        updateObject.productImageUri = this.imageId;
      }

      if (this.thumbnailId === undefined) {
        delete updateObject.productThumbnailUri;
      } else {
        updateObject.productThumbnailUri = this.thumbnailId;
      }

      updateObject.bruttoPrice = processMoneyToString(this.product.bruttoPrice);
      updateObject.packSize = parseInt(updateObject.packSize);

      // POST to API
      fetchApi("v1/products/" + this.id, "PATCH", updateObject)
        .then((res) => {
          console.log("RES", res);
          router.go(-1);
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    getProduct: function () {
      fetchApi("v1/products/" + this.id, "GET")
        .then(async (data) => {
          //console.log("prod: ", data);
          this.product = data;
          this.product.bruttoPrice = processStringToMoney(
            await this.product.bruttoPrice
          );
          if (this.product.productImageUri != null) {
            this.imageUri = this.product.productImageUri + "?" + Math.random();
          }
          if (this.product.productThumbnailUri != null) {
            this.thumbnailUri =
              this.product.productThumbnailUri + "?" + Math.random();
          }
          this.deliveryTimeslots = this.product.deliveryTimeSlots;
          this.attributes = this.product.attributes;

          console.log("DATA", data);
          this.loading.product = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });

      /*fetchApi('v1/products/' + this.id + '/delivery-time-slots', 'GET')
        .then(async data => {
          this.deliveryTimeslots = data
          this.loading.timeslots = false;
        });

      fetchApi('v1/products/' + this.id + '/attributes', 'GET')
        .then(async data => {
          this.attributes = data
          this.loading.attributes = false;
        });*/
    },

    getCategories: function () {
      fetchApi("v1/categories", "GET")
        .then((data) => {
          this.categories = data;
          this.loading.category = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    addCategory: function () {
      this.addingCategory = true;
    },

    appendCategory: function () {
      this.addingCategory = false;
      this.product.categories.push(this.category);
      this.category = {
        id: null,
        name: null,
        type: null,
      };
    },

    removeCategory: function (id) {
      this.product.categories = this.product.categories.filter(
        (el) => el.id !== id
      );
    },

    addAttribute: function () {
      this.addingAttribute = true;
    },

    appendAttribute: function () {
      this.addingAttribute = false;
      this.attributes.push(this.attribute);
      this.attribute = {
        id: null,
        name: null,
        value: null,
      };
    },

    removeAttribute: function (attr) {
      this.attributes = this.attributes.filter(
        (el) =>
          el.id !== attr.id && el.name !== attr.name && el.value !== attr.value
      );
    },

    deleteProd: function (continueDelete) {
      if (continueDelete) {
        fetchApi(`v1/products/${this.id}`, "DELETE")
          .then(() => {
            router.push({ name: "ProductOverview" });
          })
          .catch((e) => {
            this.error = e;
          });
      } else {
        this.$bvModal.show("confirmDelete-Dialog");
      }
    },

    onImageId: function (fileid) {
      this.imageId = fileid;
    },

    onThumbnailId: function (fileid) {
      this.thumbnailId = fileid;
    },
  },

  async created() {
    this.getProduct();
    this.getCategories();

    if (!this.id) {
      router.push({ name: "ProductOverview" });
    }
  },
});
</script>

<style scoped>
@import "~vue-select/dist/vue-select.css";

.required {
  color: #f54842;
}
</style>
