




























































































































































































































































import { ChevronLeftIcon, SaveIcon, CheckIcon, XIcon } from "vue-feather-icons";
import VueSelect from "vue-select";
import router from "../../../router";
import { fetchApi } from "../../../lib/fetch";
import Vue from "vue";
import DPBUpload from "@/components/DPBUpload.vue";
import { BIconTelephoneMinus, BSpinner } from "bootstrap-vue";
import TimeslotComponent from "@/components/TimeslotComponent.vue";
import { processMoneyToString } from "../../../lib/processMoneyString";

export default Vue.extend({
  name: "productAdd",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
    CheckIcon,
    XIcon,
    BSpinner,
    DPBUpload,
    TimeslotComponent,
  },

  data() {
    return {
      loading: {
        category: true,
        vendor: true,
      },
      notAvailableOptions: [
        { text: "Montag", value: "MONDAY" },
        { text: "Dienstag", value: "TUESDAY" },
        { text: "Mittwoch", value: "WEDNESDAY" },
        { text: "Donnerstag", value: "THURSDAY" },
        { text: "Freitag", value: "FRIDAY" },
        { text: "Samstag", value: "SATURDAY" },
        { text: "Sonntag", value: "SUNDAY" },
        { text: "alle Feiertage in RLP", value: "HOLIDAY" },
      ],
      addingCategory: false,
      categories: [] as any[],
      attributes: [] as any[],
      deliveryTimeslots: [] as any[],
      addingAttribute: false,
      category: {
        id: null as string | null,
        name: null as string | null,
        type: null as string | null,
      },
      attribute: {} as any,
      product: {
        name: null as string | null,
        shortDescription: null as string | null,
        description: null as string | null,
        productImageUri: null as string | null,
        productThumbnailUri: null as string | null,
        length: null as string | number | null,
        width: null as string | number | null,
        height: null as string | number | null,
        weight: null as string | number | null,
        bruttoPrice: null as string | number | null,
        taxRate: null as string | number | null,
        deliveryFeeFactor: 0.05 as string | number | null,
        isActive: true,
        isAgeRestricted: false,
        isHighlighted: false,
        isFeaturedStartScreen: false,
        requiresCooling: false,
        isAvailable: true,
        fetchAttributesFromExternal: false,
        categories: [] as any[],
        attributes: [] as any[],
        vendorId: "" as string,
        eanNumber: "" as string,
        storeQuantity: 0 as string | number | null,
        qtyAlertThreshold: 3 as string | number | null,
        recommendedQuantity: 5 as string | number | null
      },
      vendors: [] as any[],
      vendor: null as any,
      imageUri: null as string | null | undefined,
      imageId: undefined as string | null | undefined,
      thumbnailUri: null as string | null | undefined,
      thumbnailId: undefined as string | null | undefined,
      id: "" as string,
      error: null as null,
    };
  },

  methods: {
    back: function () {
      router.push({ name: "ProductOverview" });
    },

    getVendors: function () {
      fetchApi("v1/vendors", "GET")
        .then((data) => {
          this.vendors = data;
          this.loading.vendor = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    getCategories: function () {
      fetchApi("v1/categories", "GET")
        .then((data) => {
          this.categories = data;
          this.loading.category = false;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    addCategory: function () {
      this.addingCategory = true;
    },

    appendCategory: function () {
      this.addingCategory = false;
      this.product.categories.push(this.category);
      this.category = {
        id: null,
        name: null,
        type: null,
      };
    },

    removeCategory: function (id: any) {
      this.product.categories = this.product.categories.filter(
        (el) => el.id !== id
      );
    },

    addAttribute: function () {
      this.addingAttribute = true;
    },

    appendAttribute: function () {
      this.addingAttribute = false;
      this.attributes.push(this.attribute);
      this.attribute = {
        id: null,
        name: null,
        value: null,
      };
    },

    removeAttribute: function (attr: any) {
      this.attributes = this.attributes.filter(
        (el) =>
          el.id !== attr.id && el.name !== attr.name && el.value !== attr.value
      );
    },

    save: function () {
      // Set vendor ID
      this.product.vendorId = this.vendor.id;

      // Parse number input to float
      this.product.length = parseFloat(this.product.length as string);
      this.product.width = parseFloat(this.product.width as string);
      this.product.height = parseFloat(this.product.height as string);
      this.product.weight = parseFloat(this.product.weight as string);
      this.product.bruttoPrice = processMoneyToString(
        parseFloat(this.product.bruttoPrice as string)
      );
      this.product.taxRate = parseFloat(this.product.taxRate as string);
      this.product.deliveryFeeFactor = parseFloat(
        this.product.deliveryFeeFactor as string
      );

      this.product.storeQuantity = parseInt(this.product.storeQuantity as string);
      this.product.qtyAlertThreshold = parseInt(this.product.qtyAlertThreshold as string);
      this.product.recommendedQuantity = parseInt(this.product.recommendedQuantity as string);

      console.log(JSON.stringify(this.product));
      const body = {
        ...this.product,
        attributes: this.attributes,
        deliveryTimeSlots: this.deliveryTimeslots,
      };

      fetchApi("v1/products", "POST", body)
        .then((data) => {
          if (!data) {
            throw data["message"];
          }
          /*this.deliveryTimeslots.forEach(async slot => {
            console.log('New Timeslot')
            await fetchApi(`v1/products/${data.id}/delivery-time-slots`, 'POST', {'Authorization': 'Bearer ' +localStorage.getItem('accessToken')}, slot);
          });*/

          /*this.attributes.forEach(async attr => {
            await fetchApi(`v1/products/${data.id}/attributes`, 'POST', {'Authorization': 'Bearer ' +localStorage.getItem('accessToken')}, attr);
          });*/
          if (data.id) {
            router.push({ name: "ProductDetails", params: { id: data.id } });
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    onImageId: function (fileid: any) {
      this.imageId = fileid;
    },

    onThumbnailId: function (fileid: any) {
      this.thumbnailId = fileid;
    },
  },

  watch: {
    "product.eanNumber"() {
      if (this.product.eanNumber.length <= 0)
        this.product.fetchAttributesFromExternal = false
    }
  },

  async created() {
    this.getVendors();
    this.getCategories();
  },
});
