







import Vue from "vue";
import router from "../router";

export default Vue.extend({
  name: "NotFound",

  methods: {
    navigator: function(page: string){
      router.push({name: page})
    }
  }

});
