

























































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
    ChevronLeftIcon,
    SaveIcon,
    CheckSquareIcon,
    PrinterIcon,
    CheckIcon,
    EyeOffIcon,
} from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import router from "@/router";
import { formatDateFull } from "@/lib/formatDate";

export default Vue.extend({
    name: "ProductList",
    components: {
        ChevronLeftIcon,
        CheckSquareIcon,
        PrinterIcon,
        SaveIcon,
        DPBUpload,
        timeslotComponent,
        QrcodeVue,
        CheckIcon,
        EyeOffIcon,
    },
    data() {
        return {
            error: null,
            fields: [
                { key: "name", label: "Name", sortable: false },
                { key: "storeQuantity", label: "Vorhanden", sortable: false },
                { key: "recommendedQuantity", label: "Empfohlen", sortable: false },
                { key: "needed", label: "Benötigt", sortable: false },
                { key: "product", label: "Hinzufügen", sortable: false },
            ],
            products: [],
            formatDateFull
        };
    },
    methods: {
        getProducts: async function () {
            await fetchApi(
                "v1/products/shoppinglist", "GET"
            )
                .then(async (data) => {
                    if (data) {
                        console.log("products", data);
                        this.products = data;
                    } else {
                        alert('Fehler beim laden der Produkte!');
                    }
                })
                .catch((e) => {
                    console.error("ERR", e);
                    alert('Fehler beim laden der Produkte!');
                });
        },
        updateProductQuantity: async function (product: any) {
            if (product.newStoreQuantity > 0) {
                const productId = product.id;
                product.storeQuantity = parseInt(product.storeQuantity) + parseInt(product.newStoreQuantity);
                delete product.newStoreQuantity;
                delete product.id;
                delete product.createdAt;
                delete product.updatedAt;
                delete product.deletedAt;
                delete product.productImageUri;
                delete product.productThumbnailUri;
                fetchApi("v1/products/" + productId, "PATCH", product)
                    .then((res) => {
                        alert('Menge upgedated!');
                        this.getProducts();
                    })
                    .catch((e) => {
                        alert('Fehler beim speichern!');
                        this.error = e;
                    });
            } else {
                alert('Hinzugefügte Menge muss größer 0 sein...');
            }
        }
    },
    async created() {
        await this.getProducts();
    },
});
