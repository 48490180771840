const SERVER_HOST = process.env.VUE_APP_SERVER_HOST;

export async function fetchApi(route: string, method: string, body?: any): Promise<any> {
    return new Promise((resolve, reject) => {
        let requestOptions;

        if (body === null || body === undefined) {
            requestOptions = {
                method: method,
                headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' +localStorage.getItem('accessToken')  },
            }
        }
        else {
            requestOptions = {
                method: method,
                headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' +localStorage.getItem('accessToken')},
                body: JSON.stringify(body)
            }

        }


        fetch(`${SERVER_HOST}/${route}`, requestOptions)
            .then(async response => {
                if (response.status == 200 || response.status == 201) {
                    try {
                        const data = await response.json();
                        resolve(data)
                    }
                    catch (e){
                        if (e instanceof SyntaxError){
                            resolve({})}
                        else{
                            throw "Error parsing JSON"
                        }
                    }
                }
                else{
                    const data = await response.json();
                    throw data["message"]
                }
            })
            .catch(error => {
                console.error('There was an error fetching!', error);
                reject(error);
            });
    });
}
