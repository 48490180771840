
















































































































import { ChevronLeftIcon, SaveIcon, CheckSquareIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import kostenstellen from "@/assets/kostenstellen";
import dayjs from "dayjs";

export default Vue.extend({
  name: "VouchersDetails",
  components: {
    ChevronLeftIcon,
    CheckSquareIcon,
    SaveIcon,
  },
  data() {
    return {
      id: this.$route.params.id,
      error: null,
      voucher: null,
      kostenstellen: kostenstellen,
      isOperator: false as boolean,
    };
  },
  methods: {
    async getVoucher() {
      const res = await fetchApi("v1/vouchers/" + this.id, "GET", null);
      this.voucher = {
        ...res,
        validBefore: {
          date: dayjs(res.validBefore).format("YYYY-MM-DD"),
          time: dayjs(res.validBefore).format("HH:mm"),
        },
        validAfter: {
          date: dayjs(res.validAfter).format("YYYY-MM-DD"),
          time: dayjs(res.validAfter).format("HH:mm"),
        },
      };
    },
    async update() {
      this.error = "";
      try {
        const res = await fetchApi(
          `v1/vouchers/${this.id}`,
          "PUT",
          this.updateBody
        );
        alert("Gutschein wurde angepasst.");
        router.push({ name: "VouchersOverview" });
      } catch (error) {
        console.log(error);
        this.error = error;
      }
    },
  },
  async created() {
    await this.getVoucher();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
  computed: {
    updateBody() {
      let resultKeyValuePairs: any = {
        costCenter: `${this.voucher.costCenter}`,
        subject: this.voucher.subject,
        isActive: this.voucher.isActive,
        maximumRedemptionCount: parseInt(this.voucher.maximumRedemptionCount),
      };

      // Format: 2007-08-31T16:47+01:00
      const timeZoneOffset = new Date().getTimezoneOffset(); // utc default gut?
      const timezoneSuffix = // '%2B' -> '+' for query string
        timeZoneOffset === -60
          ? "+01:00"
          : timeZoneOffset === -120
            ? "+02:00"
            : "+00:00";

      if (this.voucher.validAfter.date) {
        if (this.voucher.validAfter.time) {
          resultKeyValuePairs[
            "validAfter"
          ] = `${this.voucher.validAfter.date}T${this.voucher.validAfter.time}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "validAfter"
          ] = `${this.voucher.validAfter.date}T00:00:00${timezoneSuffix}`;
        }
      } else {
        resultKeyValuePairs["validAfter"] = null;
      }
      if (this.voucher.validBefore.date) {
        if (this.voucher.validBefore.time) {
          resultKeyValuePairs[
            "validBefore"
          ] = `${this.voucher.validBefore.date}T${this.voucher.validBefore.time}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "validBefore"
          ] = `${this.voucher.validBefore.date}T00:00:00${timezoneSuffix}`;
        }
      } else {
        resultKeyValuePairs["validBefore"] = null;
      }

      return resultKeyValuePairs;
    },
  },
});
