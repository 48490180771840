




















































import { ChevronLeftIcon } from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import VueSelect from "vue-select";
import router from "@/router";
import { formatDateFull } from "@/lib/formatDate";
import { processStringToMoney } from "@/lib/processMoneyString";

export default Vue.extend({
    name: "TransactionDetails",
    components: {
        ChevronLeftIcon,
        VueSelect,
    },

    data() {
        return {
            id: this.$route.params.id,
            transaction: null,
            loading: true,
            error: null,
            formatDateFull,
            processStringToMoney,
        };
    },
    methods: {
        getTransaction: async function () {
            await fetchApi("v1/payment/transaction-check/" + this.id, "GET", null)
                .then(async (data) => {
                    if (data) {
                        console.log("tr DATA", data);
                        this.transaction = data;
                    } else {
                        alert('Fehler beim laden der Transaktion!');
                    }
                })
                .catch((e) => {
                    console.error("ERR", e);
                    alert('Fehler beim laden der Transaktion!');
                });;
            this.loading = false;
        },

    },
    async created() {
        await this.getTransaction();
    },
});
