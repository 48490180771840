






































































































import Vue from "vue";
import SidebarMoD from "@/components/SidebarMoD.vue";
import { fetchApi } from "@/lib/fetch";
import store from "@/store";
import { PlusCircleIcon, SaveIcon, Trash2Icon } from "vue-feather-icons";

export default Vue.extend({
  name: "ServiceHours",
  components: {
    SidebarMoD,
    PlusCircleIcon,
    SaveIcon,
    Trash2Icon
  },
  data() {
    return {
      role: store.state.role,
      loading: true,
      error: null,
      showAdd: false,
      serviceHours: [],
      isOperator: false as boolean,
      id: "",
      serviceHour: {
        timeframeStart: "",
        timeframeEnd: "",
        weekday: "",
      },
      selectedItem: null,
      infoMessage: "",
      weekdays: [
        {
          name: "Montag",
          dbName: "MONDAY",
        },
        {
          name: "Dienstag",
          dbName: "TUESDAY",
        },
        {
          name: "Mittwoch",
          dbName: "WEDNESDAY",
        },
        {
          name: "Donnerstag",
          dbName: "THURSDAY",
        },
        {
          name: "Freitag",
          dbName: "FRIDAY",
        },
        {
          name: "Samstag",
          dbName: "SATURDAY",
        },
        {
          name: "Sonntag",
          dbName: "SUNDAY",
        },
      ],
    };
  },
  methods: {
    getServiceHours: async function () {
      fetchApi(`v1/timeslots`, "GET", null)
        .then((data) => {
          this.serviceHours = data;
          this.serviceHours.forEach((sh) => {
            sh.weekday = this.makeWeekdayString(sh.weekday);
            sh.timeframeStart = sh.timeframeStart.substring(
              0,
              sh.timeframeStart.length - 3
            );
            sh.timeframeEnd = sh.timeframeEnd.substring(
              0,
              sh.timeframeEnd.length - 3
            );
          });
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    setServiceHour: async function () {
      const day = this.filterWeekday(this.serviceHour.weekday);
      const sH = {
        timeframeStart: this.serviceHour.timeframeStart + ":00",
        timeframeEnd: this.serviceHour.timeframeEnd + ":00",
        weekday: day,
      };
      console.log("SH", sH);
      fetchApi(`v1/timeslots`, "POST", sH)
        .then((data) => {
          //console.log(data);
          sH.weekday = this.makeWeekdayString(sH.weekday);
          this.serviceHours.push(sH);
          this.getServiceHours;
          this.infoMessage = this.setInfoMessage(0);
          this.$bvModal.show('infoModal');
          this.showAdd = false;
        })
        .catch((e) => {
          //console.error("ERR", e);
          this.error = e;
          this.infoMessage = this.setInfoMessage(3);
        });
    },
    editServiceHour: async function (item) {
      const path = `v1/timeslots/${item.id}`;
      const day = this.filterWeekday(item.weekday);
      const sH = {
        timeframeStart: item.timeframeStart + ":00",
        timeframeEnd: item.timeframeEnd + ":00",
        weekday: day,
      };
      fetchApi(path, "PATCH", sH)
        .then((data) => {
          //console.log(data);
          this.infoMessage = this.setInfoMessage(1);
          this.$bvModal.show('infoModal');
        })
        .catch((e) => {
          //console.error("ERR", e);
          this.error = e;
          this.infoMessage = this.setInfoMessage(3);
        });
    },
    deleteServiceHour: async function (item) {
      const path = `v1/timeslots/${item.id}`;
      const day = this.filterWeekday(item.weekday);
      const sH = {
        timeframeStart: item.timeframeStart + ":00",
        timeframeEnd: item.timeframeEnd + ":00",
        weekday: day,
      };
      fetchApi(path, "DELETE", sH)
        .then((data) => {
          //console.log(data);
          const index = this.serviceHours.indexOf(item, 0);
          if (index > -1) {
            this.serviceHours.splice(index, 1);
          }
          this.infoMessage = this.setInfoMessage(2);
          this.$bvModal.show('infoModal');
        })
        .catch((e) => {
          //console.error("ERR", e);
          this.error = e;
          this.infoMessage = this.setInfoMessage(3);
        });
    },
    setInfoMessage: function (mode) {
      return mode === 0
        ? "Die Service-Zeit wurde gespeichert!"
        : mode === 1
          ? "Die Service-Zeit wurde geändert!"
          : mode === 2
            ? "Die Service-Zeit wurde gelöscht!"
            : "Unbekannter Fehler";
    },
    makeWeekdayString: function (d) {
      return d === "MONDAY"
        ? "Montag"
        : d === "TUESDAY"
          ? "Dienstag"
          : d === "WEDNESDAY"
            ? "Mittwoch"
            : d === "THURSDAY"
              ? "Donnerstag"
              : d === "FRIDAY"
                ? "Freitag"
                : d === "SATURDAY"
                  ? "Samstag"
                  : "Sonntag";
    },
    filterWeekday: function (v) {
      const wd = this.weekdays.filter((d) => {
        return d.name === v;
      });
      return wd[0].dbName;
    },
  },
  async created() {
    await this.getServiceHours();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
