












































































































































































import { ChevronLeftIcon, SaveIcon, DeleteIcon, XCircleIcon } from "vue-feather-icons";
import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import DPBUpload from "@/components/DPBUpload.vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import dayjs from "dayjs";

export default Vue.extend({
    name: "advertisementsEditor",
    components: {
        ChevronLeftIcon,
        SaveIcon,
        DPBUpload,
        DeleteIcon,
        XCircleIcon,
        Autocomplete
    },
    data() {
        return {
            id: this.$route.params.id,
            advertisement: {
                name: '',
                isActiveApp: false,
                isActiveWeb: false,
                adImageUri: null,
                adImageUriApp: null,
                adType: 'GENERAL',
                showStart: "",
                showEnd: "",
                additionalText1: "",
                additionalText2: "",
                webLink: "",
                targetId: undefined,
                priority: 3 as number,
            },
            initialAdDataFromServer: {} as any,
            error: null,
            imageId: undefined,
            imageIdApp: undefined,
            imageUri: undefined,
            imageUriApp: undefined,
            types: [
                'GENERAL', 'VENDOR', 'PRODUCT', 'CATEGORY'
            ],
            priorities: [
                { text: "Höchste", value: 5 },
                { text: "Hoch", value: 4 },
                { text: "Mittel", value: 3 },
                { text: "Niedrig", value: 2 },
                { text: "Niedrigste", value: 1 },
            ],
            selectedTargetId: '',
            startDate: '',
            startTime: '00:00:00',
            endDate: '',
            endTime: '00:00:00',
            acObject: null as any,
            targetName: '',
            additionalText1: '',
            leftOverChars1: 255 as number,
            additionalText2: '',
            leftOverChars2: 255 as number,
            isScheduled: false,
            loading: true,
        };
    },
    watch: {
        additionalText1(newText, oldText) {
            this.leftOverChars1 = 255 - newText.length;
        },
        additionalText2(newText, oldText) {
            this.leftOverChars2 = 255 - newText.length;
        }
    },
    methods: {
        getAdvertisement: async function () {
            console.log("get: ", this.id);
            if (this.id && this.id !== '') {
                fetchApi("v1/advertisement/" + this.id, "GET")
                    .then((data) => {
                        console.log("ad: ", data)
                        this.initialAdDataFromServer = data;
                        this.advertisement.name = this.initialAdDataFromServer.name
                        this.advertisement.isActiveApp = this.initialAdDataFromServer.isActiveApp
                        this.advertisement.isActiveWeb = this.initialAdDataFromServer.isActiveWeb
                        this.advertisement.adType = this.initialAdDataFromServer.adType
                        this.advertisement.additionalText1 = this.initialAdDataFromServer.additionalText1
                        this.advertisement.additionalText2 = this.initialAdDataFromServer.additionalText2
                        this.additionalText1 = this.initialAdDataFromServer.additionalText1
                        this.additionalText2 = this.initialAdDataFromServer.additionalText2
                        this.advertisement.showStart = this.initialAdDataFromServer.showStart
                        this.advertisement.showEnd = this.initialAdDataFromServer.showEnd
                        this.advertisement.adImageUri = this.initialAdDataFromServer.adImageUri
                        this.advertisement.adImageUriApp = this.initialAdDataFromServer.adImageUriApp
                        this.advertisement.webLink = this.initialAdDataFromServer.webLink
                        this.advertisement.targetId = this.initialAdDataFromServer.targetId
                        this.startDate = dayjs(this.advertisement.showStart).format("YYYY-MM-DD");
                        this.startTime = dayjs(this.advertisement.showStart).format("HH:mm");
                        this.endDate = dayjs(this.advertisement.showEnd).format("YYYY-MM-DD");
                        this.endTime = dayjs(this.advertisement.showEnd).format("HH:mm");

                        if (data.targetName && data.targetName !== '') {
                            this.targetName = data.targetName;
                        }

                        if (this.advertisement.adImageUri != null) {
                            this.imageUri = this.advertisement.adImageUri + "?" + Math.random();
                        }

                        if (this.advertisement.adImageUriApp != null) {
                            console.log("image: ", this.advertisement.adImageUriApp)
                            this.imageUriApp = this.advertisement.adImageUriApp + "?" + Math.random();
                            console.log("image2: ", this.imageUriApp)
                        }
                        this.loading = false;
                    })
                    .catch((e) => {
                        alert('Fehler! Etwas ist schiefgelaufen');
                        this.error = e;
                    });
            } else {
                this.removeTarget();
            }
        },

        validateFields: function () {
            let isOK = true;

            if (this.advertisement.isActiveApp === true && !this.imageUriApp) {
                isOK = false;
                alert('Für die anzeige in der App wurde kein Bild hinterlegt!');
            }

            if (this.advertisement.isActiveWeb === true && !this.imageUri) {
                isOK = false;
                alert('Für die anzeige im Shop wurde kein Bild hinterlegt!');
            }

            if (this.advertisement.adType !== 'GENERAL' && (this.advertisement.targetId === null || this.advertisement.targetId === '')) {
                isOK = false;
                alert('Für die angegebenen Typ wurde kein Ziel verknüft!');
            }

            if (this.isScheduled === true) {
                if (this.startDate === null || this.endDate === null || this.startDate === '' || this.endDate === '') {
                    isOK = false;
                    alert('Start-Datum und End-Datum müssen angegeben werden!');
                } else {
                    let testStart = dayjs(this.formatDate(this.startDate, this.startTime));
                    let testEnd = dayjs(this.formatDate(this.endDate, this.endTime));
                    if (testStart.isAfter(testEnd)) {
                        isOK = false;
                        alert('Start-Datum muss vor dem End-Datum liegen!');
                    }
                }
            }

            if (isOK === true) {
                this.save();
            }
        },

        save: function () {
            if (this.imageId === undefined) {
                delete this.advertisement.adImageUri;
            } else {
                this.advertisement.adImageUri = this.imageId;
            }

            if (this.imageIdApp === undefined) {
                delete this.advertisement.adImageUriApp;
            } else {
                this.advertisement.adImageUriApp = this.imageIdApp;
            }

            if (this.advertisement.webLink && this.advertisement.webLink !== '' && !this.advertisement.webLink.startsWith("http://") && !this.advertisement.webLink.startsWith("https://")) {
                const prefix = "http://";
                this.advertisement.webLink = prefix.concat(this.advertisement.webLink);
            }

            this.advertisement.additionalText1 = this.additionalText1;
            this.advertisement.additionalText2 = this.additionalText2;

            if (this.isScheduled) {
                this.advertisement.showStart = this.formatDate(this.startDate, this.startTime);
                this.advertisement.showEnd = this.formatDate(this.endDate, this.endTime);
            } else {
                delete this.advertisement.showStart;
                delete this.advertisement.showEnd;
            }
            console.log("AD: ", this.advertisement);
            if (!this.id && this.id !== '') {
                fetchApi("v1/advertisement", "POST", this.advertisement)
                    .then((data) => {
                        console.log("test: ", data);
                        alert('Anzeige erfolgreich gespeichert!');
                    })
                    .catch((e) => {
                        console.error("ERR", e);
                        alert('Fehler beim speichern! Etwas ist schiefgelaufen');
                        this.error = e;
                    });
            } else {
                fetchApi("v1/advertisement/" + this.id, "PATCH", this.advertisement)
                    .then((data) => {
                        console.log("test: ", data);
                        alert('Anzeige erfolgreich gespeichert!');
                    })
                    .catch((e) => {
                        console.error("ERR", e);
                        alert('Fehler beim speichern! Etwas ist schiefgelaufen');
                        this.error = e;
                    });
            }
        },
        deleteAd: function () { },
        onImageId: function (fileid) {
            this.imageId = fileid;
        },
        onImageIdApp: function (fileidApp) {
            this.imageIdApp = fileidApp;
        },
        searchTarget: async function (input) {
            if (input.length < 3) {
                return [];
            }
            console.log("search: ", input);
            return fetchApi(
                `v1/advertisement/autocomplete/?limit=100&q=` + input + `&t=` + this.advertisement.adType,
                "GET",
                null
            );
        },
        getResultValueTarget(result) {
            this.acObject = result;
            return result.name;
        },
        submitTarget(result) {
            this.targetName = result.name;
            this.advertisement.targetId = result.id;
        },
        removeTarget() {
            this.advertisement.adType = 'GENERAL';
            this.targetName = '';
            this.advertisement.targetId = null;
        },
        formatDate(date: string, time: string) {
            // Format: 2007-08-31T16:47+01:00
            let result;
            const timeZoneOffset = new Date().getTimezoneOffset(); // utc default gut?
            const timezoneSuffix = // '%2B' -> '+' for query string
                timeZoneOffset === -60
                    ? "+01:00"
                    : timeZoneOffset === -120
                        ? "+02:00"
                        : "+00:00";

            if (date) {
                if (time) {
                    result = date + 'T' + time + timezoneSuffix
                } else {
                    result = date + 'T00:00:00' + timezoneSuffix
                }
            }

            return result;
        },


    },
    async created() {
        await this.getAdvertisement();
    },
});
