export function makeStateString(string: string) {
    return string === 'ACCEPTED' ? 'Angenommen' :
        string === 'PENDING' ? 'Wartend' :
            string === 'PREPARED' ? 'Vorbereitet' :
                string === 'VEHICLE_ASSIGNED' ? 'Zugewiesen' :
                    string === 'IN_DELIVERY' ? 'In Auslieferung' :
                        string === 'COMPLETED' ? 'Abgeschlossen' :
                            string === 'REJECTED' ? 'Abgelehnt' :
                                string === 'CANCELLED' ? 'Abgebrochen' :
                                    string === 'SELF_PICKUP' ? 'Selbstabholung' :
                                        string === 'PICKED_UP' ? 'Abgeholt' :
                                            string === 'OVERDUE' ? 'Überfällig' : 
                                                string === 'NO_SHOW' ? 'Kein Kunde' :
                                                    string === 'ACTIVE' ? 'Aktiv' : 
                                                        string === 'DISPATCHED' ? 'Zugewiesen' : 
                                                            string === 'CREATING' ? 'Creating' : 'Fehler';
}
