






















import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 11,
      center: [49.349353, 8.141384],
      geojsonRegion: {
        // dummy for initial load
        type: "Point",
        coordinates: [49.349353, 8.141384],
      },
      //geojsonAreas: {
      // dummy for initial load
      //  type: "Point",
      //  coordinates: [49.3493, 8.14134],
      //},
      fillColor: "#e4ce7f",
      enableTooltip: true,
    };
  },
  methods: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#ECEFF1",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 1,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip("<div>Name:" + feature.properties.name + "</div>", {
          permanent: true,
          sticky: true,
        });
      };
    },
  },
  async created() {
    try {
      const data = await fetchApi("v1/regions", "GET");
      // data.modRegion, data.dpbRegion, data.areas
      this.geojsonRegion = data.dpbRegion;
      //this.geojsonAreas = data.areas;
    } catch (err) {
      console.error(err);
      alert(err);
    }
  },
});
