


























































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import { ChevronLeftIcon, SaveIcon, CheckSquareIcon } from "vue-feather-icons";
import router from "../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import { makeStateString } from "@/lib/makeStateString";
import { makeCausedByString } from "@/lib/makeCausedByString";

export default Vue.extend({
  name: "RideDetails",
  components: {
    ChevronLeftIcon,
    CheckSquareIcon,
    SaveIcon,
    DPBUpload,
    timeslotComponent,
    QrcodeVue,
  },
  data() {
    return {
      makeStateString,
      makeCausedByString,
      id: this.$route.params.id,
      isVendor: true as boolean,
      isAdmin: false as boolean,
      isOperator: false as boolean,
      loading: true as boolean,
      ride: null as any,
      rideStatusFields: [
        { key: "createdAt", label: "Datum/Uhrzeit" },
        { key: "causedBy", label: "Geändert von" },
        { key: "name", label: "Name"},
        { key: "state", label: "Status" },
      ],
      items: null as any,
      options: [
        { value: "ACCEPTED", text: "Angenommen", disabled: true },
        { value: "PENDING", text: "Wartend", disabled: true },
        { value: "PREPARED", text: "Vorbereitet" },
        { value: "VEHICLE_ASSIGNED", text: "Zugewiesen" },
        { value: "IN_DELIVERY", text: "In Auslieferung" },
        { value: "COMPLETED", text: "Abgeschlossen" },
        { value: "REJECTED", text: "Abgelehnt", disabled: true },
        { value: "CANCELLED", text: "Abgebrochen" },
      ],
      error: null,
      isLogisticRide: false as boolean,
    };
  },
  methods: {
    getRide: async function () {
      await fetchApi("v1/delivery-rides/" + this.id, "GET")
        .then(async (data) => {
          if (data != null) {
            console.log("RIDE DATA", data);
            this.ride = data;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    getOrderInfo: function (id: string) {
      router.push({ name: "OrderDetails", params: { id } });
    },
    openAutofleet: function (vehicleId: string, rideId: string) {
      window.open(
        "https://control.autofleet.io/i2rFSquL5gtNZupkKD5VWz/vehicle/" +
          vehicleId +
          "/ride/" +
          rideId
      );
    },
    setOrderRideState: function (event: any) {
      console.log(event);
      fetchApi(
        "v1/orders/" +
          this.ride.order.id +
          "/vendor-order/" +
          this.ride.vendorOrder.id +
          "/change-state",
        "PATCH",
        { rideState: event }
      )
        .then((data) => {
          console.log("RIDE STATECHANGE", data);
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          return;
        });
    },

    setLogisticRideState: function (event: any) {
      console.log(event);

      fetchApi(
          `v1/delivery-rides/${this.ride.id}/change-state`,
          'PATCH',
          { rideState: event }
      )
          .then(data => {
            console.log('RIDE STATECHANGE', data);
          })
          .catch(e => {
            console.error('ERR', e);
            this.error = e;
            return;
          })
    },
    extractName: function(user: any) {
      if(user) {
        return user.firstName + " " + user.lastName;
      }
      return "";
    }
  },
  async created() {
    await this.getRide();
    this.isVendor = this.$store.state.role === "VENDOR";
    this.isAdmin = this.$store.state.role === "ADMIN";
    this.isOperator = this.$store.state.role === "OPERATOR";
    this.isLogisticRide = !this.ride.order
  },
});
