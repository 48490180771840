






































































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
  processStringToMoney,
  processMoneyToString,
} from "@/lib/processMoneyString";
import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";

export default Vue.extend({
  name: "VendorAdd",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    DPBUpload,
    timeslotComponent,
  },

  data() {
    return {
      id: "" as string,
      loading: true,
      vendor: {
        name: "" as string,
        email: null as string,
        phoneNumber: null as string,
        deliveryFeeFactor: 1 as any,
        minOrderValue: 500 as number,
        hasClickAndCollect: false as boolean,
        hasHomeDelivery: true as boolean,
        isActive: true as boolean,
        isFeatured: false as boolean,
        isManagedShop: false as boolean,
        hasEqualDeliveryAndDropoffTimeSlots: false as boolean,
        hasSameDayDelivery: false as boolean,
        canSendOrderEmails: true as boolean,
        hasExtendedBooking: false as boolean,
        latestOrderTime: null as string,
        webUrl: null as string,
        instagramUrl: null as string,
        facebookUrl: null as string,
        shortDescription: null as string,
        description: null as string,
        warehousingEnabled: false as boolean,
        address: {
          addressLevel1: null as string,
          addressLevel2: null as string,
          addressLevel3: null as string,
          city: null as string,
          postalCode: null as string,
          state: null as string,
          country: "Deutschland",
        },
        pickupAddress: {
          addressLevel1: null as string,
          addressLevel2: null as string,
          addressLevel3: null as string,
          city: null as string,
          postalCode: null as string,
          state: null as string,
          country: "Deutschland",
        },
        logoImageUri: null as string,
        headerImageUri: null as string,
      },
      LogoLink: null as any,
      LogoId: undefined as any,
      HeaderLink: null as any,
      HeaderId: undefined as any,
      extendendAddress: false,
      vendorDeliveryFactor: null as any,
      MBW: null as any,
      deliveryTimeslots: [] as any[],
      error: null,
    };
  },
  methods: {
    save: function () {
      this.vendor.minOrderValue = processMoneyToString(this.MBW);
      this.vendor.deliveryFeeFactor = this.vendor.deliveryFeeFactor
        ? parseFloat(this.vendor.deliveryFeeFactor)
        : 0;

      if (!this.extendendAddress) {
        this.vendor.pickupAddress = this.vendor.address;
      }

      if (
        !this.vendor.webUrl.startsWith("http://") &&
        !this.vendor.webUrl.startsWith("https://")
      ) {
        const prefix = "http://";
        this.vendor.webUrl = prefix.concat(this.vendor.webUrl);
      }

      console.log("VENDOR", this.vendor);

      if (this.LogoId === undefined) {
        delete this.vendor.logoImageUri;
      } else {
        this.vendor.logoImageUri = this.LogoId;
      }

      if (this.HeaderId === undefined) {
        delete this.vendor.headerImageUri;
      } else {
        this.vendor.headerImageUri = this.HeaderId;
      }

      fetchApi("v1/vendors/", "POST", this.vendor)
        .then((data) => {
          console.log(data);
          router.push("VendorOverview");
        })
        .catch((e) => {
          console.error("ERR ADR1", e);
          this.error = e;
          return;
        });
    },
    onLogoId(fileId: string) {
      console.log(fileId);
      this.LogoId = fileId;
    },
    onHeaderId(fileId: string) {
      console.log(fileId);
      this.HeaderId = fileId;
    },
  },

  async created() { },
});
