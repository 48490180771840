

















import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 11,
      center: [49.349353, 8.141384],
      geojson: {
        // dummy for initial load
        type: "Point",
        coordinates: [49.349353, 8.141384],
      },
    };
  },
  async created() {
    try {
      const data = await fetchApi("v1/regions", "GET");
      // data.modRegion, data.dpbRegion, data.areas
      this.geojson = data.modRegion;
    } catch (err) {
      console.error(err);
      alert(err);
    }
  },
});
