




























































import { fetchApi } from "@/lib/fetch";
import { processStringToMoney } from "@/lib/processMoneyString";
import { ChevronLeftIcon, EditIcon } from "vue-feather-icons";
import router from "@/router";
import Vue from "vue";

export default Vue.extend({
  name: "VendorProducts",
  components: {
    EditIcon,
    ChevronLeftIcon,
  },
  data() {
    return {
      id: this.$route.params.id as String,
      products: [] as any,
      filter: null as any,
      fields: [
        { key: "name", label: "Produkt", sortable: true },
        { key: "bruttoPrice", label: "Preis" },
        { key: "isActive", label: "Status" },
        { key: "to_product", label: "" },
      ],
      error: null,
      isOperator: false
    };
  },

  methods: {
    filterFunction: function (row: any, filter: string) {
      return row.name.toLowerCase().includes(filter.toLowerCase());
    },
    getProducts: function () {
      fetchApi(`v1/vendors/${this.id}/products`, "GET")
        .then((data) => {
          this.products = data;
          for (let i = 0; i < this.products.length; i++) {
            this.products[i].bruttoPrice = processStringToMoney(
              this.products[i].bruttoPrice
            );
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    getProductDetails: function (id: string, vendor: string) {
      router.push({
        name: "ProductDetails",
        params: { id: id, vendor: vendor },
      });
    },
  },
  async created() {
    this.getProducts();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
