


































































































import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  name: "categoryAdd",
  components: {
    ChevronLeftIcon,
    SaveIcon,
  },

  data() {
    return {
      category: {},
      error: null,
    };
  },

  methods: {
    back: function () {
      router.push({ name: "CategoryOverview" });
    },

    save: function () {
      fetchApi("v1/categories", "POST", this.category)
        .then((data) => {
          console.log(data);
          if (data["id"]) {
            router.push({ name: "CategoryOverview" });
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
  },
});
