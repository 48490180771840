














































































































































































import { fetchApi } from "@/lib/fetch";
import Vue, { nextTick } from "vue";
import { CheckSquareIcon, ChevronRightIcon, ChevronDownIcon, InfoIcon, ClockIcon, CheckIcon } from "vue-feather-icons";
import router from "@/router";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";
import { makeStateString } from "@/lib/makeStateString";
import { formatDateFull } from "@/lib/formatDate";

export default Vue.extend({
  name: "PassengersBookingsOverview",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
    ChevronDownIcon,
    InfoIcon
  },
  data() {
    return {
      makeStateString,
      getBadgeClassForState,
      formatDateFull,
      selectedStateFilter: "ALL",
      filterStateOptions: [
        { text: "Alle beendete Fahrten", value: "ALL" },
        { text: "Abgeschlossene Fahrten", value: "COMPLETED" },
        { text: "Abgebrochene Fahrten", value: "CANCELLED" },
        { text: "No Show", value: "NO_SHOW" },
        { text: "Abgelehnte Fahrten", value: "REJECTED" },
        { text: "Fehlgeschlagene Fahrten", value: "FAILED" },
      ],
      timerOptions: [
        { text: "30 Sek", value: 30000 },
        { text: "1 Min", value: 60000 },
        { text: "2 Min", value: 1200000 },
        { text: "5 Min", value: 300000 },
      ],
      selectedTimerOption: null,
      selectedAttributeFilterCustomer: "",
      selectedAttributeFilterModStopStart: "",
      selectedAttributeFilterModStopEnd: "",
      filterDateStart: "",
      filterTimeStart: "",
      filterDateEnd: "",
      filterTimeEnd: "",
      // table
      fields: [
        { key: "state", label: "Status", sortable: false },
        { key: "user.displayName", label: "Kunde", sortable: false },
        {
          key: "pickupMoDStopName",
          label: "Zustieg",
          sortable: false,
        },
        {
          key: "dropoffMoDStopName",
          label: "Ausstieg",
          sortable: false,
        },
        { key: "driver", label: "Fahrer" },
      ],
      page: 1,
      limit: 15,
      rides: [],
      metaData: null as any,
      currentRides: [],
      currentMetaData: null as any,
      showFilter: false as boolean,
      countDown: 30000 as number,
      reloadTimer: null as any
    };
  },
  computed: {
    urlFilter() {
      let resultKeyValuePairs: any = {};
      if (this.selectedStateFilter !== "ALL") {
        resultKeyValuePairs["filterByState"] = this.selectedStateFilter;
      }
      if (this.selectedAttributeFilterCustomer) {
        resultKeyValuePairs["filterByCustomer"] =
          this.selectedAttributeFilterCustomer;
      }
      if (this.selectedAttributeFilterModStopStart) {
        resultKeyValuePairs["filterByMoDstopStart"] =
          this.selectedAttributeFilterModStopStart;
      }
      if (this.selectedAttributeFilterModStopEnd) {
        resultKeyValuePairs["filterByMoDstopEnd"] =
          this.selectedAttributeFilterModStopEnd;
      }

      // Format: 2007-08-31T16:47+01:00
      const timeZoneOffset = new Date().getTimezoneOffset(); // utc default gut?
      const timezoneSuffix = // '%2B' -> '+' for query string
        timeZoneOffset === -60
          ? "%2B01:00"
          : timeZoneOffset === -120
            ? "%2B02:00"
            : "%2B00:00";

      if (this.filterDateStart) {
        if (this.filterTimeStart) {
          resultKeyValuePairs[
            "filterByDateTimeAfter"
          ] = `${this.filterDateStart}T${this.filterTimeStart}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "filterByDateTimeAfter"
          ] = `${this.filterDateStart}T00:00:00${timezoneSuffix}`;
        }
      }

      if (this.filterDateEnd) {
        if (this.filterTimeEnd) {
          resultKeyValuePairs[
            "filterByDateTimeBefore"
          ] = `${this.filterDateEnd}T${this.filterTimeEnd}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "filterByDateTimeBefore"
          ] = `${this.filterDateEnd}T00:00:00${timezoneSuffix}`;
        }
      }

      return Object.keys(resultKeyValuePairs).reduce((prev, curr) => {
        return (prev += `&${curr}=${resultKeyValuePairs[curr]}`);
      }, "");
    },
  },
  methods: {
    getRideDetails: function (item: { id: string }) {
      router.push({ name: "PassengerRideDetails", params: { id: item.id } });
    },
    async onPageChanged() {
      await nextTick();
      await this.doFilter();
    },
    async onCurrentPageChanged() {
      await nextTick();
      await this.getCurrentRides();
    },
    async doResetFilter() {
      this.selectedStateFilter = "ALL";
      this.selectedAttributeFilterCustomer = "";
      this.selectedAttributeFilterModStopStart = "";
      this.selectedAttributeFilterModStopEnd = "";
      this.filterDateStart = "";
      this.filterTimeStart = "";
      this.filterDateEnd = "";
      this.filterTimeEnd = "";
      this.page = 1;
      await nextTick();
      await this.doFilter();
    },
    async doFilterButton() {
      this.page = 1;
      await nextTick();
      await this.doFilter();
      this.showFilter = !this.showFilter;
    },
    async doFilter() {
      let query = `?page=${this.page}&limit=${this.limit}&currentOnly=false`;
      if (this.urlFilter !== "") {
        query += this.urlFilter;
      }
      try {
        const data = await fetchApi(
          "v1/passenger-rides" + encodeURI(query),
          "GET"
        );
        this.metaData = data.meta;
        this.rides = data.items;
        //console.log("OLD RIDES: ", this.rides);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    async getCurrentRides() {
      let query = `?page=${this.page}&limit=${this.limit}&currentOnly=true`;
      try {
        const data = await fetchApi(
          "v1/passenger-rides" + encodeURI(query),
          "GET"
        );
        this.currentMetaData = data.meta;
        this.currentRides = data.items;
        //console.log("CURRENT RIDES: ", this.currentRides);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    updateTimer() {
      clearInterval(this.reloadTimer);
      this.countDown = this.selectedTimerOption;
      console.log("NEW CD: ", this.countDown);
      this.reloadTimer = setInterval(() => {
        this.getCurrentRides();
      }, this.countDown);
    }
  },
  async created() {
    this.selectedTimerOption = this.timerOptions[0];
    this.reloadTimer = setInterval(() => {
      this.getCurrentRides();
    }, this.countDown);
    await this.doFilter();
    await this.getCurrentRides();
  },
  beforeDestroy() {
    clearInterval(this.reloadTimer);
  }
});
