






















































import Vue from "vue";
import { fetchApi } from "../lib/fetch";
import kostenstellen from "../assets/kostenstellen";
import { formatDateFull } from "@/lib/formatDate";
import { isAccessTokenValid } from "@/lib/validAccessToken";
export default Vue.extend<any, any, any, any>({
  props: {
    user: {
      type: Object,
      required: true,
    },
    editTransactionData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      kostenstellen: kostenstellen,
      modalShow: false,
      form: {
        subject: this.editTransactionData ? "Nachbuchung Guthaben" : "",
        amount: this.editTransactionData ? String(this.editTransactionData.amount / 100) : "0",
        tag: this.editTransactionData ? this.editTransactionData.inboundService : "",
        transactionCode: this.editTransactionData ? this.editTransactionData.transactionCode : null,
        rideId: this.editTransactionData ? this.editTransactionData.rideId : null
      },
      chargeType: "credit",
      showForm: true,
      codeRequired: ['wise', 'stripe', 'qonto', 'paypal'],
      showCostCenterError: false
    };
  },
  computed: {
    newAmount() {
      let newAmount = 0;
      if (this.form.amount.includes(",")) {
        newAmount = parseFloat(this.form.amount.replace(",", ".")) * 100;
      } else {
        newAmount = parseFloat(this.form.amount) * 100;
      }
      return newAmount;
    },
    newBalance() {
      if (this.chargeType === "credit") {
        return this.user.ownBalance + this.newAmount;
      }
      return this.user.ownBalance - this.newAmount;
    },
    formValidation() {
      let isValid = true;
      if (this.form.amount <= 0) {
        isValid = false;
      }
      if (this.form.tag === '') {
        isValid = false;
      }
      if (this.codeRequired.indexOf(this.form.tag) > -1 && (this.form.transactionCode === '' || this.form.transactionCode === null)) {
        isValid = false;
      }
      if (this.form.tag === 'mod' && (this.form.rideId === null || this.form.rideId === '')) {
        isValid = false;
      }
      if (this.form.subject === '') {
        isValid = false;
      }
      return isValid;
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      try {
        const result = await fetchApi(
          `v1/users/${this.user.id}/charge`,
          "POST",
          {
            subject: this.form.subject,
            amount: Math.round(
              this.chargeType === "credit" ? this.newAmount : -this.newAmount
            ),
            costCenter: `${this.form.tag}`,
            transactionCode: this.form.transactionCode,
            rideId: this.form.rideId
          }
        );
        alert("Transaktion erfolgreich.");
      } catch (err) {
        alert("Fehler bei der Transaktion: " + JSON.stringify(err));
        return;
      }
      //reset
      this.form.amount = "0";
      this.form.subject = "";
      this.form.tag = "";
      this.form.transactionCode = null;
      // Trick to reset/clear native browser form validation state
      this.showForm = false;
      this.showForm = true;

      this.modalShow = false;
      this.$emit("on-success", this.newBalance);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.amount = "0";
      this.form.subject = "";
      this.form.tag = "";
      // Trick to reset/clear native browser form validation state
      this.showForm = false;
      this.$nextTick(() => {
        this.showForm = true;
      });
    },
  },
});
