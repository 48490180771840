



























































































































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
    ChevronLeftIcon,
    SaveIcon,
    CheckSquareIcon,
    PrinterIcon,
    CheckIcon,
    EyeOffIcon,
    InfoIcon,
    EditIcon
} from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import router from "@/router";
import { formatDateFull } from "@/lib/formatDate";
import { processStringToMoney } from "@/lib/processMoneyString";
import dayjs from "dayjs";
import DPBChargeCustomerModal from "../../../components/DPBChargeCustomerModal.vue";

export default Vue.extend({
    name: "Transactions",
    components: {
        ChevronLeftIcon,
        CheckSquareIcon,
        PrinterIcon,
        SaveIcon,
        DPBUpload,
        timeslotComponent,
        QrcodeVue,
        CheckIcon,
        EyeOffIcon,
        InfoIcon,
        DPBChargeCustomerModal,
        EditIcon,
        Autocomplete
    },
    data() {
        return {
            error: null,
            transactionFields: [
                'index',
                { key: "bookedAt", label: "Buchung am", sortable: false },
                { key: "inboundService", label: "Type", sortable: false },
                { key: "amount", label: "Betrag", sortable: false },
                { key: "transactionCode", label: "Transaktions-Code", sortable: false },
                { key: "senderName", label: "Name", sortable: false },
                { key: "senderAccount", label: "Account", sortable: false },
                { key: "subject", label: "Referenz", sortable: false },
                { key: "details", label: "" },
                { key: "edit", label: "" }
            ],
            balanceFields: [
                { key: "isActive", label: "Aktiv", sortable: true },
                { key: "name", label: "Name", sortable: true },
                { key: "calculatedBalance", label: "Berechnet", sortable: true },
                { key: "lastKnownBalance", label: "Letzter Stand", sortable: true },
                { key: "balance", label: "Kontostand", sortable: true },
                { key: "difference", label: "Differenz", sortable: true },
            ],
            timeRangeOptions: [
                { value: 'thisMonth', text: 'Dieser Monat' },
                { value: 'lastMonth', text: 'Letzer Monat' },
                { value: 'ytd', text: 'Year-To-Date' },
                { value: 'lastYear', text: 'Letzes Jahr' },
                { value: 'custom', text: 'Eigener Zeitraum...' },
            ],
            transactions: [],
            balanceChecks: [],
            formatDateFull,
            processStringToMoney,
            startDate: null,
            endDate: null,
            selectedRange: null,
            totals: {

            },
            showWaiting: false,
            customDate: {
                start: '',
                end: ''
            },
            showTimePicker: false,
            readableTimeRange: '',
            isAdmin: false,
            perPage: 20,
            currentPage: 1,
            selectedTransaction: {},
            userFilter: null as any,
            possibleUsers: null,
            possibleUsersMetaData: null as any,
            selectedUserForTransaction: null as any
        };
    },
    methods: {
        onChargeModalSuccess: function () {
            this.$bvModal.hide("editTransactionModal");
        },
        searchUser: async function (input) {
            if (input.length < 3) {
                return [];
            }
            return fetchApi(
                `v1/users/autocomplete/?limit=20&q=` + input,
                "GET",
                null
            );
        },
        getResultValueUser(result) {
            return result.lastName + ", " + result.firstName;
        },
        submitUser(result) {
            fetchApi(`v1/users/${result.id}`, "GET", null).then((user) => {
                this.selectedUserForTransaction = user;
            });
        },
        resetEditTransactionModal() {
            this.selectedUserForTransaction = null;
            this.selectedTransaction = {};
        },
        openTimePicker() {
            if (this.showTimePicker === false) {
                this.showTimePicker = true;
                this.$bvModal.show("timePicker");
            }
        },
        closeTimePicker() {
            if (this.showTimePicker === true) {
                this.showTimePicker = false;
                this.$bvModal.hide("timePicker");
                this.selectedRange = 'none';
                this.startDate = dayjs(this.customDate.start);
                this.endDate = dayjs(this.customDate.end).endOf('day');
                this.getTransactions();
            }
        },
        getTransactions: async function () {
            this.transactions = [];
            this.readableTimeRange = 'Zeitraum: ' + formatDateFull(this.startDate) + ' - ' + formatDateFull(this.endDate);
            let query = `?startDate=${this.startDate}&endDate=${this.endDate}&extended=${this.isAdmin}`;
            this.showWaiting = true;
            await fetchApi(
                'v1/payment/transaction-check' + encodeURI(query), "GET"
            )
                .then(async (data) => {
                    if (data) {
                        //console.log("tr DATA", data);
                        this.transactions = data.transactions;
                        this.totals = data.totals;
                    } else {
                        alert('Fehler beim laden der Transaktionen!');
                    }
                })
                .catch((e) => {
                    console.error("ERR", e);
                    alert('Fehler beim laden der Transaktionen!');
                });
            this.showWaiting = false;
        },
        getBalanceChecks: async function () {
            this.balanceChecks = [];
            this.showWaiting = true;
            await fetchApi(
                'v1/payment/balance-check', "GET"
            )
                .then(async (data) => {
                    if (data) {
                        this.balanceChecks = data;
                        this.showWaiting = false;
                    } else {
                        alert('Fehler beim laden der Guthabenprüfungen!');
                        this.showWaiting = false;
                    }
                })
                .catch((e) => {
                    console.error("ERR", e);
                    alert('Fehler beim laden der Guthabenprüfungen!');
                    this.showWaiting = false;
                });
        },
        getTransactionDetails: function (item: { id: string }) {
            const routeData = router.resolve({ name: 'TransactionDetails', params: { id: item.id } });
            window.open(routeData.href, '_blank');
        },
        editTransaction: function (item) {
            this.selectedTransaction = item;
            this.$bvModal.show("editTransactionModal");
        }
    },
    computed: {
        rows() {
            return this.transactions.length
        },
        getTimeRange() {
            switch (this.selectedRange) {
                case 'thisMonth':
                    this.startDate = dayjs().startOf('month');
                    this.endDate = dayjs().endOf('month');
                    this.getTransactions();
                    break;
                case 'lastMonth':
                    this.startDate = dayjs().subtract(1, 'month').startOf('month');
                    this.endDate = dayjs().subtract(1, 'month').endOf('month');
                    this.getTransactions();
                    break;
                case 'ytd':
                    this.startDate = dayjs().startOf('year');
                    this.endDate = dayjs();
                    this.getTransactions();
                    break;
                case 'lastYear':
                    this.startDate = dayjs().subtract(1, 'year').startOf('year');
                    this.endDate = dayjs().subtract(1, 'year').endOf('year');
                    this.getTransactions();
                    break;
                case 'custom':
                    if (this.showTimePicker === false) {
                        this.openTimePicker();
                    }
                    break;
                case 'none':
                    break;
                default:
                    this.startDate = dayjs().startOf('month');
                    this.endDate = dayjs().endOf('month');
                    this.getTransactions();
            }

            return null;
        },

    },

    async created() {
        this.isAdmin = this.$store.state.roles.includes("ADMIN");
    },
});
