






































































































































































































































import {
  ChevronLeftIcon,
  SaveIcon,
  CheckSquareIcon,
  PlusCircleIcon,
  Trash2Icon,
  LinkIcon,
} from "vue-feather-icons";
import Vue from "vue";
import VueSelect from "vue-select";
import store from "@/store";
import { fetchApi } from "@/lib/fetch";
import { createId } from "@/lib/uuidGenerator";
import Autocomplete from "@trevoreyre/autocomplete-vue";

export default Vue.extend<any, any, any, any>({
  name: "LogisticBooking",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
    CheckSquareIcon,
    PlusCircleIcon,
    Trash2Icon,
    LinkIcon,
    Autocomplete,
  },
  data() {
    return {
      deleteForm: true as boolean,
      isVendor: false as boolean,
      error: "" as string,
      hasExtendedBooking: false as boolean,
      form: {
        contactPersonName: null as string,
        address: null as string,
        lat: null as string,
        lng: null as string,
        contactPersonPhone: null as string,
        packageId: null as string,
        packageDescription: null as string,
        numberOfItems: 1 as number,
        notes: null as string,
        beforeTime: null as string,
        afterTime: null as string,
        companyName: null as string,
        companyAddress: null as string,
        companyPhone: null as string,
        companyLat: null as string,
        companyLng: null as string,
        externalId: null as string,
      } as any,
      day: null as any,
      today: false as boolean,
      selectedDay: [] as any[],
      selectedTime: [] as any[],
      selectedStartTimePickup: [] as any[],
      selectedEndTimePickup: [] as any[],
      selectedStartTimeDropoff: [] as any[],
      selectedEndTimeDropoff: [] as any[],
      vormittag: false as boolean,
      showNoteTodayAM: false as boolean,
      showNoteTodayPM: false as boolean,
      amStart: 9 as number,
      amEnd: 14 as number,
      pmStart: 14 as number,
      pmEnd: 19 as number,
      bd: null as any,
      at: null as any,
      bt: null as any,
      dayArray: [] as any[],
      timeArray: [] as any[],
      vendorTimeArray: [
        { value: "vormittag", label: "Vormittag (9 - 14 Uhr)" },
        { value: "nachmittag", label: "Nachmittag (14 - 19 Uhr)" },
      ],
      estimation: null as {
        estimated_price_brutto: number,
        estimated_price_netto: number,

      },
      estimationFailed: false as boolean,
      timesLinked: [] as boolean[],
      bookingControl:
        this.$store.state.role === "ADMIN" || this.hasExtendedBooking || this.$store.state.roles.includes('OPERATOR'),
    };
  },
  methods: {
    searchUser: async function (input) {
      if (input.length < 1) {
        return [];
      }
      return fetchApi(
        `v1/users/autocomplete?limit=100&q=` + input,
        "GET",
        null
      );
    },

    getResultValueUser(result) {
      return result.lastName + ", " + result.firstName;
    },

    submitUser(result) {
      this.error = "";
      fetchApi(`v1/users/${result.id}`, "GET", null)
        .then((user) => {
          this.form.contactPersonName = user.firstName + " " + user.lastName;
          this.form.contactPersonPhone = "+" + user.phoneNumber;
          if (!user.address || user.address.addressLevel1 === "") {
            alert("autocompleted user has no address");
            return;
          }
          this.form.address =
            user.address.addressLevel1 +
            ", " +
            user.address.postalCode +
            " " +
            user.address.city;

          return fetchApi(
            `v1/utils/geocode/address?q=${encodeURI(this.form.address)}`,
            "GET",
            null
          );
        })
        .then((res) => {
          this.form.lat = res.coordinates[1];
          this.form.lng = res.coordinates[0];
        })
        .catch((err) => {
          this.error = "Fehler beim automatischen Ausfüllen des Benutzers";
        });
    },

    searchVendor: async function (input) {
      if (input.length < 1) {
        return [];
      }
      return fetchApi(
        `v1/vendors/autocomplete/?limit=100&q=` + input,
        "GET",
        null
      );
    },

    getResultValueVendor(result) {
      return result.name;
    },

    submitVendor(result) {
      this.form.companyName = result.name;
      const address = result.addresses.filter(
        (address) => address.type === "VENDOR_PICKUP"
      )[0];
      console.log("vendor, ", JSON.stringify(result));
      this.form.companyAddress =
        address.addressLevel1 + ", " + address.postalCode + " " + address.city;
      this.form.companyPhone = result.phoneNumber;
      this.form.companyLat = address.geocodedLocation.coordinates[1];
      this.form.companyLng = address.geocodedLocation.coordinates[0];
    },

    send: async function (event) {
      event.preventDefault();
      if (!this.isFormDaysOk) return;
      this.error = "";

      try {
        // Create new body for every day

        this.form.externalId = await createId();

        let body = {
          ...this.form,
          deliveryTimes: [],
          numberOfItems:
            typeof this.form.numberOfItems === "number"
              ? this.form.numberOfItems
              : parseInt(this.form.numberOfItems),
        };
        for (let i = 0; i < this.selectedDay.length; i++) {
          let d = new Date(this.selectedDay[i].value);
          let btp = null;
          let atp = null;
          let btd = null;
          let atd = null;

          if (this.bookingControl) {
            atp =
              this.selectedStartTimePickup[i].value !== null &&
              d.setHours(this.selectedStartTimePickup[i].value, 0, 0);
            btp =
              this.selectedEndTimePickup[i].value !== null &&
              d.setHours(this.selectedEndTimePickup[i].value, 0, 0);
            atd =
              this.selectedStartTimeDropoff[i].value !== null &&
              d.setHours(this.selectedStartTimeDropoff[i].value, 0, 0);
            btd =
              this.selectedEndTimeDropoff[i].value !== null &&
              d.setHours(this.selectedEndTimeDropoff[i].value, 0, 0);
          } else {
            // Check if selected Day is Today
            let today = this.isToday(d);
            // Today & Vormittag & Booking Slot Vormittag
            if (
                today &&
                this.vormittag &&
                this.selectedTime[i] &&
                this.selectedTime[i].value === "vormittag"
            ) {
              btp = d.setHours(this.pmEnd, 0, 0);
              atp = d.setHours(this.pmStart, 0, 0);
              btd = d.setHours(this.pmEnd, 0, 0);
              atd = d.setHours(this.pmStart, 0, 0);
              // Today & Nachmittag & Booking Slot Nachmittag
            } else if (
                today &&
                !this.vormittag &&
                this.selectedTime[i] &&
                this.selectedTime[i].value === "nachmittag"
            ) {
              const tomorrow = new Date(d);
              tomorrow.setDate(tomorrow.getDate() + 1);
              btp = tomorrow.setHours(this.amEnd, 0, 0);
              atp = tomorrow.setHours(this.amStart, 0, 0);
              btd = tomorrow.setHours(this.amEnd, 0, 0);
              atd = tomorrow.setHours(this.amStart, 0, 0);
              // All other Slots
            } else {
              btp =
                  this.selectedTime[i] &&
                  this.selectedTime[i].value === "vormittag"
                      ? d.setHours(this.amEnd, 0, 0)
                      : d.setHours(this.pmEnd, 0, 0);
              atp =
                  this.selectedTime[i] &&
                  this.selectedTime[i].value === "vormittag"
                      ? d.setHours(this.amStart, 0, 0)
                      : d.setHours(this.pmStart, 0, 0);

              btd =
                  this.selectedTime[i] &&
                  this.selectedTime[i].value === "vormittag"
                      ? d.setHours(this.amEnd, 0, 0)
                      : d.setHours(this.pmEnd, 0, 0);
              atd =
                  this.selectedTime[i] &&
                  this.selectedTime[i].value === "vormittag"
                      ? d.setHours(this.amStart, 0, 0)
                      : d.setHours(this.pmStart, 0, 0);
            }
          }

          body.deliveryTimes.push({
            pickup: {
              afterTime: new Date(atp).toISOString(),
              beforeTime: new Date(btp).toISOString(),
            },
            dropoff: {
              afterTime: new Date(atd).toISOString(),
              beforeTime: new Date(btd).toISOString(),
            },
          });
        }

        delete body.afterTime;
        delete body.beforeTime;
        console.log(body);

        const res = await fetchApi("v1/delivery-rides", "POST", body);

        if (res) {
          alert("Abgeschickt");
          if (!this.deleteForm) {
            return;
          }
          this.form = {
            contactPersonName: null as string,
            address: null as string,
            lat: null as string,
            lng: null as string,
            contactPersonPhone: null as string,
            packageId: null as string,
            packageDescription: null as string,
            numberOfItems: 0 as number,
            notes: null as string,
            beforeTime: null as string,
            afterTime: null as string,
            companyName: null as string,
            companyAddress: null as string,
            companyPhone: null as string,
            companyLat: null as string,
            companyLng: null as string,
            externalId: null as string,
          };

          this.selectedDay = [this.dayArray[0]];
          this.selectedStartTimePickup = [this.timeArray[0]];
          this.selectedEndTimePickup = [this.timeArray[1]];
          this.selectedTime = [this.vendorTimeArray[0]];
          this.selectedStartTimeDropoff = [this.selectedStartTimePickup[0]];
          this.selectedEndTimeDropoff = [this.selectedEndTimePickup[0]];
          this.timesLinked = [true];

          this.today = false as boolean;

          this.showNoteTodayAM = false as boolean;
          this.showNoteTodayPM = false as boolean;
          this.showNoteTodayAM = false as boolean;
          this.showNoteTodayPM = false as boolean;

          return;
        }
      } catch (err) {
        this.error = JSON.stringify(err);
      }
    },
    makeDayTimeArray: function () {
      const date = new Date();
      const newDay = new Date(date);
      for (let i = 0; i < 6; i++) {
        let day = 0;
        if (i === 0) {
          day = newDay.setDate(date.getDate());
        } else {
          day = newDay.setDate(newDay.getDate() + 1);
        }
        this.dayArray.push({
          value: day,
          label: new Date(day).toLocaleString("de-DE", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        });
      }
      for (let j = 5; j < 23; j++) {
        this.timeArray.push({ value: j, label: j + ".00 Uhr" });
      }
    },
    isToday: (someDate) => {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    setDeliveryTime: function (e, i) {
      // Calculate Date by selected Day
      const d = new Date(this.selectedDay[i].value);
      // Check if selected Day is Today
      this.today = this.isToday(d);
      // Today & Vormittag & Booking Slot Vormittag
      if (
        this.today &&
        this.vormittag &&
        this.selectedTime[i] &&
        this.selectedTime[i].value === "vormittag"
      ) {
        this.bt = d.setHours(this.pmEnd, 0, 0);
        this.at = d.setHours(this.pmStart, 0, 0);
        this.showNoteTodayAM = true;
        this.showNoteTodayPM = false;
        // Today & Nachmittag & Booking Slot Nachmittag
      } else if (
        this.today &&
        !this.vormittag &&
        this.selectedTime[i] &&
        this.selectedTime[i].value === "nachmittag"
      ) {
        const tomorrow = new Date(d);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.bt = tomorrow.setHours(this.amEnd, 0, 0);
        this.at = tomorrow.setHours(this.amStart, 0, 0);
        this.showNoteTodayAM = false;
        this.showNoteTodayPM = true;
        // All other Slots
      } else {
        this.bt =
          this.selectedTime[i] && this.selectedTime[i].value === "vormittag"
            ? d.setHours(this.amEnd, 0, 0)
            : d.setHours(this.pmEnd, 0, 0);
        this.at =
          this.selectedTime[i] && this.selectedTime[i].value === "vormittag"
            ? d.setHours(this.amStart, 0, 0)
            : d.setHours(this.pmStart, 0, 0);
        this.showNoteTodayAM = false;
        this.showNoteTodayPM = false;
      }
      // Set final Pickup- and Dropoff-Times
      this.form.beforeTime = new Date(this.bt).toISOString();
      this.form.afterTime = new Date(this.at).toISOString();

      console.log("BEFORE", this.form.beforeTime);
      console.log("AFTER", this.form.afterTime);
    },
    setCompanyDeliveryTime: function (e, i, isPickup) {
      // Calculate Date by selected Day
      const d = new Date(this.selectedDay.value);

      if (isPickup) {
        this.at =
          this.selectedStartTimePickup[i].value !== null &&
          d.setHours(this.selectedStartTimePickup[i].value, 0, 0);
        this.bt =
          this.selectedEndTimePickup[i].value !== null &&
          d.setHours(this.selectedEndTimePickup[i].value, 0, 0);

        if (this.timesLinked[i]) {
          this.$set(
            this.selectedStartTimeDropoff,
            i,
            this.selectedStartTimePickup[i]
          );
          this.$set(
            this.selectedEndTimeDropoff,
            i,
            this.selectedEndTimePickup[i]
          );
        }
      }

      if (!isPickup) {
        this.at =
          this.selectedStartTimeDropoff[i].value !== null &&
          d.setHours(this.selectedStartTimeDropoff[i].value, 0, 0);
        this.bt =
          this.selectedEndTimeDropoff[i].value !== null &&
          d.setHours(this.selectedEndTimeDropoff[i].value, 0, 0);
      }

      // Set final Pickup- and Dropoff-Times
      this.form.beforeTime = new Date(this.bt).toISOString();
      this.form.afterTime = new Date(this.at).toISOString();

      console.log("BEFORE", this.form.beforeTime);
      console.log("AFTER", this.form.afterTime);
    },
    getAddressData: function (addressData) {
      this.form.address =
        addressData.route +
        " " +
        addressData.street_number +
        ", " +
        addressData.postal_code +
        " " +
        addressData.locality;
      this.form.lat = addressData.latitude;
      this.form.lng = addressData.longitude;
    },
    getCompAddressData: function (companyAddressData) {
      this.form.companyAddress =
        companyAddressData.route +
        " " +
        companyAddressData.street_number +
        ", " +
        companyAddressData.postal_code +
        " " +
        companyAddressData.locality;
      this.form.companyLat = companyAddressData.latitude;
      this.form.companyLng = companyAddressData.longitude;
    },
    getVendor: async function () {
      await fetchApi("v1/vendors/" + store.state.roleId, "GET", null)
        .then(async (data) => {
          if (data != null) {
            //this.vendor = data;
            console.log("DATA", data);
            this.hasExtendedBooking = data.hasExtendedBooking;
            this.form.companyName = data.name;
            const address = data.addresses.find(
              (x) => x.type === "VENDOR_PICKUP"
            );
            this.form.companyAddress =
              address.addressLevel1 +
              ", " +
              address.postalCode +
              " " +
              address.city;
            this.form.companyLat = address.geocodedLocation.coordinates[1];
            this.form.companyLng = address.geocodedLocation.coordinates[0];
            this.form.companyPhone = data.phoneNumber;
            console.log("ADR", address);
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },

    isPickupAfterDropoff(i) {
      if (store.state.role === "Vendor") return false;

      if (
        this.selectedEndTimePickup[i] !== null &&
        this.selectedStartTimePickup[i] !== null &&
        this.selectedEndTimeDropoff[i] !== null &&
        this.selectedStartTimeDropoff[i] !== null
      ) {
        if (
          this.selectedEndTimePickup[i].value !== null &&
          this.selectedStartTimePickup[i].value !== null &&
          this.selectedEndTimeDropoff[i].value !== null &&
          this.selectedStartTimeDropoff[i].value !== null
        ) {
          return (
            this.selectedEndTimePickup[i].value - this.selectedStartTimePickup[i].value <= 0 ||
            this.selectedEndTimeDropoff[i].value - this.selectedStartTimeDropoff[i].value <= 0
          );
        } else {
          return true;
        }
        return false;
      }

      return false;
    },

    addDay() {
      let dayIndex = this.selectedDay.length - 1;
      this.selectedDay.push(this.selectedDay[dayIndex]);

      let timePickupIndex = this.selectedTime.length - 1;
      this.selectedTime.push(this.selectedTime[timePickupIndex]);

      let startTimePickupIndex = this.selectedStartTimePickup.length - 1;
      this.selectedStartTimePickup.push(
        this.selectedStartTimePickup[startTimePickupIndex]
      );

      let endTimePickupIndex = this.selectedEndTimePickup.length - 1;
      this.selectedEndTimePickup.push(
        this.selectedEndTimePickup[endTimePickupIndex]
      );

      let startTimeDropoffIndex = this.selectedStartTimeDropoff.length - 1;
      this.selectedStartTimeDropoff.push(
        this.selectedStartTimeDropoff[startTimeDropoffIndex]
      );

      let endTimeDropoffIndex = this.selectedEndTimeDropoff.length - 1;
      this.selectedEndTimeDropoff.push(
        this.selectedEndTimeDropoff[endTimeDropoffIndex]
      );

      let timesLinkedIndex = this.timesLinked.length - 1;
      this.timesLinked.push(this.timesLinked[timesLinkedIndex]);
    },

    removeDay(index) {
      this.selectedDay.splice(index, 1);
      this.selectedTime.splice(index, 1);
      this.selectedStartTimePickup.splice(index, 1);
      this.selectedEndTimePickup.splice(index, 1);
      this.selectedStartTimeDropoff.splice(index, 1)
      this.selectedEndTimeDropoff.splice(index, 1)
      this.timesLinked.splice(index, 1);
    },

    centToEuro: function (cent: number): string {
      let numberFormatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      let euro = Number((cent / 100).toFixed(2));
      return numberFormatter.format(euro);
    },

    priceEstimate() {
      if (!this.form.numberOfItems) return (this.estimation = null);
      if (!this.form.companyLat || !this.form.companyLng)
        return (this.estimation = null);
      if (!this.form.lat || !this.form.lng) return (this.estimation = null);

      this.estimationFailed = false;

      const body = {
        pickupLng: this.form.companyLng,
        pickupLat: this.form.companyLat,
        dropoffLng: this.form.lng,
        dropoffLat: this.form.lat,
        deliveryUnits: parseInt(this.form.numberOfItems),
      };

      fetchApi("v1/delivery-rides/price-estimate", "POST", body)
        .then((data) => {
          this.estimationFailed = false;
          this.estimation = data;
        })
        .catch((err) => {
          console.error(err);
          this.estimation = null;
          this.estimationFailed = true;
        });
    },

    toggleLinked(i) {
      let linked = this.timesLinked[i];

      if (!linked) {
        this.$set(this.selectedStartTimeDropoff, i, this.selectedStartTimePickup[i])
        this.$set(this.selectedEndTimeDropoff, i, this.selectedEndTimePickup[i])
      }

      this.$set(this.timesLinked, i, !linked);
    },

    isDropoffAndPickupOverlapping(i) {
      if (this.timesLinked[i]) return true;
      try {
        return this.selectedEndTimePickup[i].value - this.selectedStartTimeDropoff[i].value >= 0
      } catch (e) {
        return false
      }
    },

    isDropoffBeforePickupDay(i) {
      if (this.timesLinked[i]) return false;
      try {
        return this.selectedEndTimeDropoff[i].value - this.selectedStartTimePickup[i].value <= 0
      } catch (e) {
        return false
      }
    }
  },

  computed: {
    isTimingOk() {
      if (!this.bookingControl) return true;
      return this.selectedDay.every((day, i) => {
        return !this.isPickupAfterDropoff(i);
      });
    },

    isOverlapping() {
      if (!this.bookingControl) return true;
      return this.selectedDay.every((day, i) => {
        return this.isDropoffAndPickupOverlapping(i);
      });
    },

    isDropoffBeforePickup() {
      if (!this.bookingControl) return false;
      return this.selectedDay.some((day, i) => {
        return this.isDropoffBeforePickupDay(i)
      })
    },

    isFormDaysOk() {
      return (
        this.isTimingOk &&
        this.isOverlapping &&
        !this.isDropoffBeforePickup
      )
    },

    dayDeletable() {
      if (this.selectedDay.length <= 1) return false;
      if (this.selectedTime.length <= 1) return false;
      if (this.selectedStartTimePickup.length <= 1) return false;
      if (this.selectedEndTimePickup.length <= 1) return false;
      return true;
    },
  },

  watch: {
    "form.numberOfItems"() {
      this.priceEstimate();
    },
    "form.companyLat"() {
      this.priceEstimate();
    },
    "form.companyLng"() {
      this.priceEstimate();
    },
    "form.lat"() {
      this.priceEstimate();
    },
    "form.lng"() {
      this.priceEstimate();
    },
  },

  async created() {
    this.makeDayTimeArray();
    store.state.role === "VENDOR" && (await this.getVendor());
    this.day = new Date();
    this.vormittag = this.day.getHours() < 12;
    this.isVendor = store.state.role === "VENDOR";
    this.bookingControl = this.$store.state.role === 'ADMIN' || this.hasExtendedBooking || this.$store.state.roles.includes('OPERATOR')

    this.selectedDay[0] = this.dayArray[0];
    this.selectedStartTimePickup[0] = this.timeArray[0];
    this.selectedEndTimePickup[0] = this.timeArray[1];
    this.selectedTime[0] = this.vendorTimeArray[0];
    this.selectedStartTimeDropoff[0] = this.selectedStartTimePickup[0];
    this.selectedEndTimeDropoff[0] = this.selectedEndTimePickup[0];
    this.timesLinked.push(true);
  },
});
