<template>
  <!-- ###############################
         ## Collapse Logistics Ride ##
         ############################# -->
  <b-collapse
      id="sidebarCollapseAdminMobile"
      class="d-md-none d-lg-none"
  >
    <b-navbar-nav>
      <b-nav-item
          href="#"
          @click="navigator('DiePfalzBringts')"
      >
        Die Pfalz bringt's
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('MobilityOnDemand')"
      >
        Mobility-on-Demand
      </b-nav-item>
      <b-nav-item
          href="#"
          @click="navigator('LogisticsBooking')"
      >
        Lieferfahrt buchen
      </b-nav-item>
    </b-navbar-nav>
  </b-collapse>
</template>

<script>
import router from "@/router/index";

export default {
  data() {
    return {
      currentRoute: null
    }
  },
  methods: {
    navigator: function (page, id) {
      router.push({name: page, params: {id: id}});
      this.currentRoute = page;
    }
  }
};
</script>
