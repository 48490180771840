
































































import { fetchApi } from "@/lib/fetch";
import Vue, { nextTick } from "vue";
import { CheckSquareIcon, ChevronRightIcon } from "vue-feather-icons";
import router from "@/router";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";

export default Vue.extend({
  name: "VouchersOverview",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
  },
  data() {
    return {
      getBadgeClassForState,
      // table
      fields: [
        { key: "code", label: "Code", sortable: true },
        { key: "subject", label: "Betreff/Beschreibung", sortable: true },
        { key: "amount", label: "Guthabenbetrag", sortable: true },
        {
          key: "redemptionCount",
          label: "Anzahl Einlösungen",
          sortable: false,
        },
        { key: "isActive", label: "Aktiv?", sortable: true },
        { key: "createdAt", label: "Erstellt", sortable: true },
        { key: "link", label: "Details" },
      ],
      page: 1,
      limit: 15,
      vouchers: [],
      metaData: null as any,
      isOperator: false as boolean,
    };
  },
  methods: {
    goToDetails(code) {
      router.push({ name: "VouchersDetails", params: { id: code } });
    },
    goToCreate() {
      router.push({ name: "VouchersCreate" });
    },
    async onPageChanged() {
      await nextTick();
      await this.doFilter();
    },
    async doFilterButton() {
      this.page = 1;
      await nextTick();
      await this.doFilter();
    },
    async doFilter() {
      let query = `?page=${this.page}&limit=${this.limit}`;
      try {
        const data = await fetchApi("v1/vouchers" + encodeURI(query), "GET");
        this.metaData = data.meta;
        this.vouchers = data.items;
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    formatDate(dateAsString: string) {
      return new Date(dateAsString).toLocaleString("de", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  async created() {
    await this.doFilter();
    this.isOperator = this.$store.state.roles.includes("OPERATOR");
  },
});
