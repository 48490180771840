import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      role: null,
      roleId: null,
      roles: null,
      name: null,
  },
  mutations: {
    UPDATE_ROLE(state,roleName) {
      state.role=roleName
    },
    UPDATE_ROLEID(state,id) {
      state.roleId=id
    },
    UPDATE_ROLES(state, roles) {
      state.roles=roles
    },
    UPDATE_NAME(state, name) {
      state.name=name
    }
  },
  actions: {
    setUserdata(context: any, payload){
      context.commit('UPDATE_ROLE',payload["role"])
      if (payload["role"]=="VENDOR"){
        context.commit('UPDATE_ROLEID',payload["vendorId"])
      }
      else if (payload["role"]=="DRIVER"){
        context.commit('UPDATE_ROLEID',payload["userId"])
      }
      context.commit('UPDATE_ROLES',payload["roles"])
      context.commit('UPDATE_NAME',payload["lastName"]+', '+payload["firstName"])
    },

  },
  modules: {
  }
})
