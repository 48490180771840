

































































































































import { ChevronLeftIcon, SaveIcon, CheckSquareIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import kostenstellen from "@/assets/kostenstellen";

export default Vue.extend({
  name: "VouchersCreate",
  components: {
    ChevronLeftIcon,
    CheckSquareIcon,
    SaveIcon,
  },
  data() {
    return {
      id: this.$route.params.id,
      voucher: {
        code: "",
        subject: "",
        redemptionCount: 0,
        maximumRedemptionCount: "1",
        amount: "0",
        costCenter: "",
        isActive: true,
        validAfter: {
          date: "",
          time: "",
        },
        validBefore: {
          date: "",
          time: "",
        },
      },
      error: "",
      kostenstellen: kostenstellen,
    };
  },
  computed: {
    createBody() {
      let resultKeyValuePairs: any = {
        costCenter: `${this.voucher.costCenter}`,
        subject: this.voucher.subject,
        code: this.voucher.code.toUpperCase(),
        isActive: this.voucher.isActive,
        maximumRedemptionCount: parseInt(this.voucher.maximumRedemptionCount),
      };

      let amount = 0;
      if (this.voucher.amount.includes(",")) {
        amount = parseFloat(this.voucher.amount.replace(",", ".")) * 100;
      } else {
        amount = parseFloat(this.voucher.amount) * 100;
      }
      resultKeyValuePairs["amount"] = amount;

      // Format: 2007-08-31T16:47+01:00
      const timeZoneOffset = new Date().getTimezoneOffset(); // utc default gut?
      const timezoneSuffix = // '%2B' -> '+' for query string
        timeZoneOffset === -60
          ? "+01:00"
          : timeZoneOffset === -120
          ? "+02:00"
          : "+00:00";

      if (this.voucher.validAfter.date) {
        if (this.voucher.validAfter.time) {
          resultKeyValuePairs[
            "validAfter"
          ] = `${this.voucher.validAfter.date}T${this.voucher.validAfter.time}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "validAfter"
          ] = `${this.voucher.validAfter.date}T00:00:00${timezoneSuffix}`;
        }
      }
      if (this.voucher.validBefore.date) {
        if (this.voucher.validBefore.time) {
          resultKeyValuePairs[
            "validBefore"
          ] = `${this.voucher.validBefore.date}T${this.voucher.validBefore.time}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "validBefore"
          ] = `${this.voucher.validBefore.date}T00:00:00${timezoneSuffix}`;
        }
      }

      return resultKeyValuePairs;
    },
  },
  methods: {
    async create() {
      this.error = "";
      try {
        const res = await fetchApi("v1/vouchers", "POST", this.createBody);
        alert("Gutschein angelegt!");
        router.push({ name: "VouchersOverview" });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async generateCode() {
      const { code } = await fetchApi("v1/vouchers/generate", "GET");
      console.log(code);
      this.voucher.code = code;
    },
  },
  async created() {},
});
