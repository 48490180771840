








































































import Vue from "vue";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import { EditIcon, PlusCircleIcon, PackageIcon } from "vue-feather-icons";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      loading: true,
      vendors: null,
      vendorsLength: 0,
      filter: null,
      fields: [
        { key: "name", label: "Händler" },
        { key: "contact", label: "Kontakt" },
        { key: "isActive", label: "Status" },
        { key: "to_vendor", label: "" },
      ],
      error: null,
      isOperator: false
    };
  },
  methods: {
    getVendorDetails: function (id: string) {
      router.push({ name: "VendorDetails", params: { id: id } });
    },
    getVendorProducts: function (id: string) {
      router.push({ name: "VendorProducts", params: { id: id } });
    },
    addVendor: function () {
      router.push({ name: "VendorAdd" });
    },
    getVendors: async function () {
      fetchApi("v1/vendors", "GET", null)
        .then((data) => {
          if (data != null) {
            console.log("VENDORS", data);
            this.vendors = data;
            this.loading = false;
            this.vendorsLength = data.length;
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
  },
  async created() {
    await this.getVendors();
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
  components: {
    EditIcon,
    PlusCircleIcon,
    PackageIcon,
  },
  computed: {
    rows(): number {
      return this.vendorsLength;
    },
    sortOptions(): any[] {
      if (!this.vendors) {
        return [];
      }
      return this.vendors
        .filter((f: any) => f.sortable)
        .map((f: any) => {
          return { text: f.label, value: f.key };
        });
    },
  },
});
