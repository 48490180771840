


































































































































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import VueSelect from "vue-select";

export default Vue.extend({
  name: "UserCustomerAdd",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    timeslotComponent,
    VueSelect,
  },

  data() {
    return {
      loading: true,
      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: null,

        street: null,
        postCode: null,
        city: null,

        hasWheelChair: false,
        needsChildrenSeat: false,
        allowsContact: false,
        needsHelpEnteringCar: false,
        hasFreeRide: false,
        createAlsoAsDriver: false,

        hasBahnCard: false,
        hasYearCard: false,
        bahnCardNumber: null,
        yearCardNumber: null,
        yearCardType: null,

        birthdayDay: null,
        birthdayMonth: null,
        birthdayYear: null,
      },
      error: null,
      yearTypeOptions: [
        { value: "Deutschlandticket", text: "Deutschlandticket" },
        { value: "eTarif Anschlussticket", text: "eTarif Anschlussticket" },
        { value: "Tages-Karte3-Tages-Karte", text: "Tages-Karte3-Tages-Karte" },
        { value: "Wochenkarte Jedermann", text: "Wochenkarte Jedermann" },
        { value: "Wochenkarte Ausbildung", text: "Wochenkarte Ausbildung" },
        { value: "Monatskarte Jedermann", text: "Monatskarte Jedermann" },
        { value: "Monatskarte Ausbildung", text: "Monatskarte Ausbildung" },
        { value: "Monatskarte Senioren", text: "Monatskarte Senioren" },
        { value: "Entdecker-Ticket", text: "Entdecker-Ticket" },
        { value: "Semester-Ticket", text: "Semester-Ticket" },
        {
          value: "Anschluss-Semester-Ticket",
          text: "Anschluss-Semester-Ticket",
        },
        {
          value: "Semester-Ticket plus Westpfalz",
          text: "Semester-Ticket plus Westpfalz",
        },
        { value: "Jahreskarte Jedermann", text: "Jahreskarte Jedermann" },
        { value: "Rhein-Neckar-Ticket", text: "Rhein-Neckar-Ticket" },
        { value: "Karte ab 60", text: "Karte ab 60" },
        { value: "AboPlus VRN/KVV", text: "AboPlus VRN/KVV" },
        { value: "Job-Ticket", text: "Job-Ticket" },
        { value: "JobTicket BW", text: "JobTicket BW" },
        { value: "MAXX-Ticket", text: "MAXX-Ticket" },
        { value: "SuperMAXX-Ticket", text: "SuperMAXX-Ticket" },
        { value: "Jahreskarte Ausbildung", text: "Jahreskarte Ausbildung" },
        {
          value: "Rheinland-Pfalz/Saarland-Ticket",
          text: "Rheinland-Pfalz/Saarland-Ticket",
        },
        {
          value: "PfalzcardRabattkarteSchwerbehindertenausweis",
          text: "PfalzcardRabattkarteSchwerbehindertenausweis",
        },
      ],
    };
  },
  methods: {
    save: function () {
      const body = {
        ...this.user,
        birthdayDay: this.user.birthdayDay
          ? parseInt(this.user.birthdayDay)
          : null,
        birthdayMonth: this.user.birthdayMonth
          ? parseInt(this.user.birthdayMonth)
          : null,
        birthdayYear: this.user.birthdayYear
          ? parseInt(this.user.birthdayYear)
          : null,
      };
      fetchApi("v1/users", "POST", body)
        .then((data) => {
          //console.log("DATA", data);
          router.push({ name: "UserDetails", params: { id: data.id } });
        })
        .catch((e) => {
          console.error("ERR ADR1", e);
          this.error = e;
          return;
        });
    },
  },
});
