<template>
  <div class="timeslot-table">
    <!--
    <b-table
        responsive
        striped
        hover
        :items="opHours"
        :fields="days"
        id="categoriesTable">
    </b-table>
    -->
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th></th>
          <th v-for="weekday in this.weekdays">{{ weekday.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hour in this.opHours">
          <td>{{ hour }}:00</td>
          <td :class="isSlotMarked(weekday, hour) ? 'table-primary' : ''" v-for="weekday in weekdays"
            v-on:click="() => { deleteSlot(currentSlot(weekday.dbName, hour)) }">
            {{
              currentSlot(weekday.dbName, hour).start.minute > 0 &&
                currentSlot(weekday.dbName, hour).end.minute > 0 &&
                currentSlot(weekday.dbName, hour).start.hour == hour &&
                currentSlot(weekday.dbName, hour).end.hour == hour ?
                `${hour}:${currentSlot(weekday.dbName, hour).start.minute} - ${hour}:${currentSlot(weekday.dbName,
                  hour).end.minute}` :
                ''
            }}

            {{
              currentSlot(weekday.dbName, hour).start.minute > 0 &&
                currentSlot(weekday.dbName, hour).start.hour == hour &&
                currentSlot(weekday.dbName, hour).end.hour != hour ?
                `${hour}:${currentSlot(weekday.dbName, hour).start.minute}` :
                ''
            }}

            {{
              currentSlot(weekday.dbName, hour).start.minute > 0 &&
                currentSlot(weekday.dbName, hour).start.hour != hour &&
                currentSlot(weekday.dbName, hour).end.hour == hour ?
                `${hour}:00` :
                ''
            }}

            {{
              currentSlot(weekday.dbName, hour).end.minute > 0 &&
                currentSlot(weekday.dbName, hour).start.hour == hour &&
                currentSlot(weekday.dbName, hour).end.hour != hour ?
                `${hour}:00` :
                ''
            }}

            {{
              currentSlot(weekday.dbName, hour).end.minute > 0 &&
                currentSlot(weekday.dbName, hour).end.hour == hour &&
                currentSlot(weekday.dbName, hour).start.hour != hour ?
                `${hour}:${currentSlot(weekday.dbName, hour).end.minute}` :
                ''
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <form class="row mb-1" v-if="adding">
      <vue-select :options="weekdays" :reduce="weekday => weekday.dbName" class="col-8" label="name" multiple
        v-model="timeslot.weekday" />
      <vue-timepicker :minute-interval="15" class="col-2" close-on-complete placeholder="Start Zeit" required
        v-model="timeslot.timeframeStart"></vue-timepicker>
      <vue-timepicker :minute-interval="15" class="col-2" close-on-complete placeholder="End Zeit" required
        v-model="timeslot.timeframeEnd"></vue-timepicker>
    </form>
    <button class="btn btn-outline-secondary btn-sm" v-on:click.prevent="toggleAdding">Timeslot hinzufügen</button>

    <b-modal id="confirmDelete-Dialog" title="Löschung bestätigen"
      v-on:ok="() => { this.deleteSlot(this.selectedSlot, true) }" ok-variant="danger" ok-title="Löschen"
      cancel-variant="outline-secondary" cancel-title="Abbrechen">
      <p class="mt-4 mb-1">Sind Sie sicher, dass Sie folgendes Lieferzeitfenster löschen möchten?</p><br>
      <p class="my-0">
        Tag: {{ this.selectedSlot.weekday.name }}<br>
        Start: {{ this.selectedSlot.start.hour }}:{{ this.selectedSlot.start.minute }}<br>
        Ende: {{ this.selectedSlot.end.hour }}:{{ this.selectedSlot.end.minute }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import VueSelect from "vue-select";
import { BModal } from "bootstrap-vue";

export default {
  name: "timeslotComponent",
  props: {
    value: { type: Array, required: true, default: () => [] }
  },
  components: {
    VueTimepicker, VueSelect, BModal
  },
  data() {
    return {
      timeslot: {
        timeframeStart: '',
        timeframeEnd: '',
        weekday: ''
      },
      days: [
        { key: 'MONDAY', label: 'Montag' },
        { key: 'TUESDAY', label: 'Dienstag' },
        { key: 'WEDNESDAY', label: 'Mittwoch' },
        { key: 'THURSDAY', label: 'Donnerstag' },
        { key: 'FRIDAY', label: 'Freitag' },
        { key: 'SATURDAY', label: 'Samstag' },
        { key: 'SUNDAY', label: 'Sonntag' },
      ],
      weekdays: [
        {
          name: 'Montag',
          dbName: 'MONDAY',
        },
        {
          name: 'Dienstag',
          dbName: 'TUESDAY',
        },
        {
          name: 'Mittwoch',
          dbName: 'WEDNESDAY',
        },
        {
          name: 'Donnerstag',
          dbName: 'THURSDAY',
        },
        {
          name: 'Freitag',
          dbName: 'FRIDAY',
        },
        {
          name: 'Samstag',
          dbName: 'SATURDAY',
        },
        {
          name: 'Sonntag',
          dbName: 'SUNDAY',
        },
      ],
      adding: false,
      opHours: this.range(0, 23),
      selectedSlot: {
        start: {
          hour: 0,
          minute: 0,
        },
        end: {
          hour: 0,
          minute: 0,
        },
        weekday: ''
      }
    }
  },

  methods: {
    handleInput: function () {
      // Return if one input field is empty
      if (
        this.timeslot.timeframeStart.includes('HH') ||
        this.timeslot.timeframeStart.includes('mm') ||
        this.timeslot.timeframeStart === '' ||
        this.timeslot.timeframeEnd.includes('HH') ||
        this.timeslot.timeframeEnd.includes('mm') ||
        this.timeslot.timeframeEnd === ''
      ) {
        return
      }

      if (typeof this.timeslot.weekday === "object") {
        this.timeslot.weekday.forEach(wd => {
          this.timeslot.timeframeStart = this.timeslot.timeframeStart + ':00'
          this.timeslot.timeframeEnd = this.timeslot.timeframeEnd + ':00'
          this.value.push({ ...this.timeslot, weekday: wd });
        })
      } else {
        this.timeslot.timeframeStart = this.timeslot.timeframeStart + ':00'
        this.timeslot.timeframeEnd = this.timeslot.timeframeEnd + ':00'
        this.value.push(this.timeslot);
      }

      console.log(this.value)
    },

    toggleAdding: function () {
      if (this.adding) {
        console.log()
        this.adding = false;
        this.handleInput();
        this.clearInput();
      } else if (!this.adding) {
        this.adding = true;
      }
    },

    deleteSlot: async function (slot, continueDelete = false) {
      let el = this.value.find((element) => {
        if (
          element.timeframeStart === `${slot.start.hour}:${slot.start.minute}:00` &&
          element.timeframeEnd === `${slot.end.hour}:${slot.end.minute}:00` &&
          (element.weekday === slot.weekday.dbName || element.weekday === slot.weekday)
        ) {
          return true;
        }
      })

      let position = this.value.indexOf(el);
      if (position < 0) return;

      if (continueDelete) {
        this.value.splice(position, 1);
      }
      else {
        this.selectedSlot = slot;
        this.selectedSlot.weekday = this.weekdays.find((element) => element.dbName == slot.weekday);
        this.$bvModal.show('confirmDelete-Dialog');
      }
    },

    clearInput: function () {
      this.timeslot = {
        timeframeStart: '',
        timeframeEnd: '',
        weekday: ''
      };
    },

    range: function (start, end, step = 1) {
      let result = [];

      for (let i = parseInt(start); i <= parseInt(end); i++) {
        if (i % step === 0) result.push(i)
      }

      return result
    },

    hourHasTimeslots: function (hour) {
      let hasTimeslots = false;

      this.value.forEach(t => {

        let slot = {};
        slot.start = t.timeframeStart.split(':')[0]
        slot.end = t.timeframeEnd.split(':')[0]

        if (this.range(slot.start, slot.end).indexOf(hour) >= 0) {
          hasTimeslots = true;
        }

      })

      return hasTimeslots;
    },

    isSlotMarked: function (weekday, hour) {
      let isMarked = false;

      this.value.forEach(t => {
        let slot = {};
        slot.start = t.timeframeStart.split(':')[0]
        slot.end = t.timeframeEnd.split(':')[0]

        if (weekday.dbName === t.weekday) {
          if (this.range(slot.start, slot.end).indexOf(hour) >= 0) {
            isMarked = true;
          }
        }

      })

      return isMarked;
    },

    currentSlot: function (weekday, hour) {
      let curSlot = {
        start: {
          hour: null,
          minute: null
        },
        end: {
          hour: null,
          minute: null
        },
        weekday: null,
        isStart: null
      };

      this.value.forEach(slot => {

        let startHour = slot.timeframeStart.split(':')[0];
        let endHour = slot.timeframeEnd.split(':')[0];

        if (slot.weekday === weekday && this.range(startHour, endHour).indexOf(hour) >= 0) {

          curSlot = {
            weekday: slot.weekday,
            start: {
              hour: slot.timeframeStart.split(':')[0],
              minute: slot.timeframeStart.split(':')[1],
            },
            end: {
              hour: slot.timeframeEnd.split(':')[0],
              minute: slot.timeframeEnd.split(':')[1],
            },
            isStart: startHour === hour
          };

        }
      })

      return curSlot;
    }
  }
};
</script>

<style scoped>
/*@import './../styles/dashboard.css';
@import './../styles/style.css';*/
@import '~vue2-timepicker/dist/VueTimepicker.css';
</style>
