

















































































































































































import router from "../../router";
import { fetchApi } from "@/lib/fetch";
import Vue, { nextTick } from "vue";
import { BSpinner } from "bootstrap-vue";
import { EditIcon, PlusCircleIcon, ChevronDownIcon, InfoIcon } from "vue-feather-icons";
import { makeStateString } from "@/lib/makeStateString";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";
import { formatDateFull, formatDateTime } from "@/lib/formatDate";

export default Vue.extend({
  name: "LogisticsBookingsOverview",
  components: {
    BSpinner,
    EditIcon,
    PlusCircleIcon,
    ChevronDownIcon,
    InfoIcon
  },
  data() {
    return {
      getBadgeClassForState,
      makeStateString,
      formatDateFull,
      formatDateTime,
      selectedStateFilter: "ALL",
      filterStateOptions: [
        { text: "Alle beendete Fahrten", value: "ALL" },
        { text: "Abgeschlossene Fahrten", value: "COMPLETED" },
        { text: "Abgebrochene Fahrten", value: "CANCELLED" },
        { text: "Abgelehnte Fahrten", value: "REJECTED" },
        { text: "Fehlgeschlagene Fahrten", value: "FAILED" },
      ],
      timerOptions: [
        { value: 30000, text: "30 Sek" },
        { value: 60000, text: "1 Min" },
        { value: 120000, text: "2 Min" },
        { value: 300000, text: "5 Min" },
      ],
      filterOptionsTypeSelect: [
        { text: "Alle", value: "all" },
        { text: "DPB", value: "dpb" },
        { text: "Logistik", value: "log" },
      ],
      selectedTimerOption: null,
      selectedAttributeFilterVendor: "",
      selectedAttributeFilterCustomer: "",
      selectedAttributeFilterType: "",
      filterDatePickup: "",
      filterTimePickup: "",
      filterDateDropoff: "",
      filterTimeDropoff: "",
      rides: null as any,
      metaData: null as any,
      page: 1,
      currentPage: 1,
      limit: 15,
      totalItems: 0,
      loading: true,
      error: null,
      fields: [
        { key: "state", label: "Status", tdClass: 'align-middle' },
        { key: "vendor", label: "Abholung" },
        { key: "recipient", label: "Lieferung" },
        { key: "type", label: "Typ", tdClass: 'align-middle' },
      ],
      currentRides: [],
      currentMetaData: null as any,
      showFilter: false as boolean,
      countDown: 30000 as number,
      reloadTimer: null as any
    };
  },
  computed: {
    urlFilter() {
      let resultKeyValuePairs: any = {};
      if (this.selectedStateFilter !== "ALL") {
        resultKeyValuePairs["filterByState"] = this.selectedStateFilter;
      }
      if (this.selectedAttributeFilterVendor) {
        resultKeyValuePairs["filterByVendor"] =
          this.selectedAttributeFilterVendor;
      }
      if (this.selectedAttributeFilterCustomer) {
        resultKeyValuePairs["filterByCustomer"] =
          this.selectedAttributeFilterCustomer;
      }
      if (this.selectedAttributeFilterType) {
        resultKeyValuePairs["filterByType"] =
          this.selectedAttributeFilterType;
      }

      // Format: 2007-08-31T16:47+01:00
      const timeZoneOffset = new Date().getTimezoneOffset(); // utc default gut?
      const timezoneSuffix = // '%2B' -> '+' for query string
        timeZoneOffset === -60
          ? "%2B01:00"
          : timeZoneOffset === -120
            ? "%2B02:00"
            : "%2B00:00";

      if (this.filterDatePickup) {
        if (this.filterTimePickup) {
          resultKeyValuePairs[
            "filterByDateTimePickup"
          ] = `${this.filterDatePickup}T${this.filterTimePickup}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "filterByDateTimePickup"
          ] = `${this.filterDatePickup}T00:00:00${timezoneSuffix}`;
        }
      }

      if (this.filterDateDropoff) {
        if (this.filterTimeDropoff) {
          resultKeyValuePairs[
            "filterByDateTimeDropoff"
          ] = `${this.filterDateDropoff}T${this.filterTimeDropoff}${timezoneSuffix}`;
        } else {
          resultKeyValuePairs[
            "filterByDateTimeDropoff"
          ] = `${this.filterDateDropoff}T00:00:00${timezoneSuffix}`;
        }
      }

      return Object.keys(resultKeyValuePairs).reduce((prev, curr) => {
        return (prev += `&${curr}=${resultKeyValuePairs[curr]}`);
      }, "");
    },
  },
  methods: {
    async loadCurrentPage() {
      await nextTick();
      this.getCurrentRides(this.currentPage, this.limit);
    },
    async loadPage() {
      await nextTick();
      this.getRides(this.page, this.limit);
    },
    async doResetFilter() {
      this.selectedStateFilter = "ALL";
      this.selectedAttributeFilterVendor = "";
      this.selectedAttributeFilterCustomer = "";
      this.selectedAttributeFilterType = "";
      this.filterDatePickup = "";
      this.filterTimePickup = "";
      this.filterDateDropoff = "";
      this.filterTimeDropoff = "";
      this.page = 1;
      await nextTick();
      await this.getRides();
    },
    async doFilterButton() {
      this.page = 1;
      await nextTick();
      await this.getRides();
      this.showFilter = !this.showFilter;
    },
    async getRides(page: number = 1, limit: number = 15) {
      this.loading = true;
      let query = `/paged?page=${this.page}&limit=${limit}&currentOnly=false`;
      if (this.urlFilter !== "") {
        query += this.urlFilter;
      }
      try {
        const data = await fetchApi(
          "v1/delivery-rides" + encodeURI(query),
          "GET"
        );
        this.metaData = data.meta;
        this.rides = data.items;
        //console.log("OLD RIDES: ", this.rides);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    async getCurrentRides(page: number = 1, limit: number = 15) {
      let query = `/paged?page=${this.currentPage}&limit=${limit}&currentOnly=true`;
      try {
        const data = await fetchApi(
          "v1/delivery-rides" + encodeURI(query),
          "GET"
        );
        this.currentMetaData = data.meta;
        this.currentRides = data.items;
        //console.log("CURRENT RIDES: ", this.currentRides);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    },
    bookRide: function () {
      router.push({ name: "LogisticsBooking" });
    },
    getRideDetails: function (item: { id: string }) {
      router.push({ name: "RideDetails", params: { id: item.id } });
    },
    updateTimer() {
      clearInterval(this.reloadTimer);
      this.countDown = this.selectedTimerOption;
      this.reloadTimer = setInterval(() => {
        this.getCurrentRides();
      }, this.countDown);
    }
  },
  async created() {
    this.selectedTimerOption = this.timerOptions[0];
    this.reloadTimer = setInterval(() => {
      this.getCurrentRides();
    }, this.countDown);
    this.getRides();
    this.getCurrentRides();
  },
  beforeDestroy() {
    clearInterval(this.reloadTimer);
  }
});
