export function formatDateFull(string: string) {
    return new Date(string).toLocaleString("de", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
}

export function formatDateTime(string: string) {
  return new Date(string).toLocaleString("de", {
      hour: "numeric",
      minute: "numeric",
    })
}