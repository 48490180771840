















































































































































































































































































import Vue from "vue";
import router from "@/router";
import { fetchApi } from "@/lib/fetch";
import store from "@/store";
import { makeStateString } from "@/lib/makeStateString";
import { CheckSquareIcon, ChevronRightIcon } from "vue-feather-icons";
import dayjs from "dayjs";
import {
  getBadgeClassForState,
  isSelfPickupOverdue,
  calculateDayDiff,
} from "@/lib/uiStatesUtil";

export default Vue.extend({
  name: "Orders",
  components: {
    ChevronRightIcon,
    CheckSquareIcon,
  },
  data() {
    return {
      role: store.state.role,
      vendorId: store.state.roleId,
      getBadgeClassForState,
      makeStateString,
      isSelfPickupOverdue,
      calculateDayDiff,
      page: 1,
      limit: 10,
      loading: true,
      error: null,
      filter: null as string,
      deliveries: null,
      metaData: null as any,
      customer: null as any,
      isVendor: true as boolean,
      isAdmin: false as boolean,
      isOperator: false as boolean,
      items: null as any,
      isPrinting: false as boolean,
      svgString: null as string,
      productListDate: dayjs().add(0, "day").format("YYYY-MM-DD"),
      fieldsAdmin: [{ key: "vendors", label: "Umfang", sortable: false }],
      fieldsVendor: [
        { key: "date", label: "Bestellt für", sortable: true },
        { key: "customer", label: "Kunde", sortable: true },
        { key: "vendors", label: "Umfang", sortable: false },
      ],
      fieldsVendorOrders: [
        { key: "date", label: "" },
        { key: "name", label: "" },
        { key: "price", label: "" },
        { key: "actions", label: "", tdClass: "tableActions" },
      ],
      fieldsVendorOrdersElements: [
        { key: "amount", label: "" },
        { key: "name", label: "" },
        { key: "price", label: "" },
      ],
      options: [
        { value: "ACCEPTED", text: "Angenommen", disabled: true },
        { value: "PENDING", text: "Wartend", disabled: true },
        { value: "PREPARED", text: "Vorbereitet" },
        { value: "VEHICLE_ASSIGNED", text: "Zugewiesen" },
        { value: "IN_DELIVERY", text: "In Auslieferung" },
        { value: "COMPLETED", text: "Abgeschlossen" },
        { value: "REJECTED", text: "Abgelehnt", disabled: true },
        { value: "CANCELLED", text: "Abgebrochen" },
      ],
      optionsPickup: [
        { value: "SELF_PICKUP", text: "Selbstabholung" },
        { value: "PICKED_UP", text: "Abgeholt" },
        { value: "OVERDUE", text: "Überfällig" },
        { value: "CANCELLED", text: "Abgebrochen" },
      ],
      hideObsoleteOrders: false,
      selectedItem: null,
      unfinishedPickupOrders: 0 as number,
    };
  },

  watch: {
    // whenever question changes, this function will run
    filter(newFilter, oldFilter) {
      if (newFilter !== "" && newFilter !== null && newFilter !== undefined) {
        this.filter = newFilter;
        this.filterFunc(newFilter);
      } else {
        this.getDeliveries();
      }
    },
  },

  methods: {
    loadPage(page: number) {
      this.loading = true;
      this.filterFunc(this.filter, page, this.limit);
    },
    triggerFilterByObsolete() {
      this.filter = this.filter;
    },
    calculateUnfinishedPickupOrders: function () {
      for (const item in this.deliveries) {
        const target = this.deliveries[item];
        if (isSelfPickupOverdue(target)) {
          this.unfinishedPickupOrders++;
        }
      }
    },
    getDeliveries: async function (page: number = 1, limit: number = 10) {
      fetchApi(
        `v1/orders?page=${page}&${limit}&hideObsoleteOrders=${this.hideObsoleteOrders}`,
        "GET",
        null
      )
        .then((data) => {
          if (data != null) {
            //console.log("ORDERS", data);
            this.deliveries = data.items;
            this.metaData = data.meta;
            this.calculateUnfinishedPickupOrders();
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    getDeliveriesDetails: function (item: { id: string }) {
      //console.log("ITEM", item);
      router.push({ name: "OrderDetails", params: { id: item.id } });
    },
    filterFunc: function (filter: string, page: number = 1, limit: number = 10) {
      this.loading = true;
      const nameFilter = typeof filter === "boolean" ? "" : filter;

      let queryUrl = `v1/orders?page=${page}&limit=${limit}`;

      if (this.filter != null && this.filter != '') {
        queryUrl += `&filterByLastName=${this.filter}`;
      }

      queryUrl += `&hideObsoleteOrders=${this.hideObsoleteOrders}`;

      fetchApi(
        //`v1/orders?page=1&limit=10&filterByLastName=${nameFilter}&hideObsoleteOrders=${this.hideObsoleteOrders}`,
        queryUrl,
        "GET",
        null
      )
        .then((data) => {
          //console.log("ORDERS", data);
          this.deliveries = data.items;
          this.metaData = data.meta;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    /*filterFunc: function (row: any, filter: string) {
      const isNumber = /^\d+$/.test(filter[0]);
      if (!isNumber) {
        return row.customer.lastName
          .toLowerCase()
          .includes(filter.toLowerCase());
      } else {
        console.log("R", row);
        const dateString = row.pickupDate.split("-");
        const day = dateString[2] + ".";
        const month = dateString[1] + ".";
        const year = dateString[0];
        const readableString = day + month + year;
        return readableString.includes(filter);
      }
    },*/
    showProductList: function () {
      router.push({
        name: "ProductList",
        params: { date: this.productListDate },
      });
    },
    async getSVG(vendorOrderId, orderId, isDayOrder) {
      const fetchString = isDayOrder
        ? "/v1/orders/vendor-ordered-product-list/" +
        this.vendorId +
        "/" +
        this.productListDate +
        "/receipt-svg"
        : "/v1/orders/" +
        orderId +
        "/vendor-order/" +
        vendorOrderId +
        "/receipt-svg";
      return fetch(process.env.VUE_APP_SERVER_HOST + fetchString, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
        .then((r) => r.text())
        .then((data) => {
          //console.log("svgString", data);
          return Promise.resolve(data);
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          return Promise.reject(e);
        });
    },
    async print(vendorOrderId, orderId, isDayOrder) {
      this.isPrinting = true;
      this.svgString = await this.getSVG(vendorOrderId, orderId, isDayOrder);
      this.$nextTick(async () => {
        await this.$htmlToPaper("printArea", null, () => {
          console.log("Printing done");
          this.isPrinting = false;
        });
      });
    },
    setOrderState: function (
      event: any,
      vendorOrderId: string,
      orderId: string
    ) {
      if (event == "PREPARED") {
        this.print(vendorOrderId, orderId, false).then(() => { });
      }
      fetchApi(
        "v1/orders/" +
        orderId +
        "/vendor-order/" +
        vendorOrderId +
        "/change-state",
        "PATCH",
        { rideState: event }
      )
        .then((data) => {
          this.selectedItem.item.orderStateChanges[0].state = event;
          this.calculateUnfinishedPickupOrders();
          this.$bvModal.hide("editModal");
          return true;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
          return;
        });
    },
    makeAddressHtmlVendor: function (customer, deliveryRide) {
      let address = '';
      if (!customer) {
        return "[not set]";
      }
      if (deliveryRide != null) {
        address = deliveryRide.recipientAddress;
      }
      return (
        customer.firstName +
        " " +
        customer.lastName +
        "</b><br/>" +
        address +
        "<br/>" +
        "<i>" +
        customer.phoneNumber +
        "</i></div>"
      );
    },
    makeAddressHtmlAdmin: function (customer, deliveryRide) {
      let address = '';
      if (!customer) {
        return "[not set]";
      }
      if (deliveryRide != null) {
        address = deliveryRide.recipientAddress;
      }
      return (
        "<b>" +
        customer.firstName +
        " " +
        customer.lastName +
        "</b><br/>" +
        address +
        "<br/>" +
        "<i>Tel. " +
        customer.phoneNumber +
        "</i>"
      );
    },
    dayDiffString: function (date, state) {
      const diffDays = calculateDayDiff(date);
      if (
        diffDays === 0 &&
        state !== "COMPLETED" &&
        state !== "REJECTED" &&
        state !== "CANCELLED"
      ) {
        return '<p class="dayString today">Abholung heute</p>';
      } else if (diffDays === 1) {
        //return '<p class="dayString past">(Abholung gestern)</p>'
      } else if (diffDays > 1) {
        //return '<p class="dayString past">(Abholung vor ' + diffDays.toString() + ' Tagen)</p>'
      } else if (diffDays === -1) {
        return '<p class="dayString tomorrow">Abholung morgen</p>';
      } else if (diffDays < -1) {
        return (
          '<p class="dayString future">Abholung in ' +
          Math.abs(diffDays).toString() +
          " Tagen</p>"
        );
      }
    },
  },
  async created() {
    await this.getDeliveries(1, this.limit);
    this.isVendor = this.$store.state.role === "VENDOR";
    this.isAdmin = this.$store.state.role === "ADMIN";
    this.isOperator = this.$store.state.roles.includes('OPERATOR');
  },
});
