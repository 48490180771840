import dayjs from "dayjs";

export function getBadgeClassForState(string: string) {
    return string === 'ACTIVE' ? 'success' :
        string === 'INACTIVE' ? 'secondary' :
            string === 'PENDING' ? 'secondary' :
                string === 'COMPLETED' ? 'secondary' :
                    string === 'CANCELLED' ? 'warning' :
                        string === 'NO_SHOW' ? 'warning' :
                            string === 'ACTIVE' ? 'primary' :
                                string === 'DISPATCHED' ? 'primary' :
                                    string === 'FAILED' ? 'danger' :
                                        string === 'REJECTED' ? 'danger' :
                                            string === 'ACCEPTED' ? 'primary' :
                                                string === 'PREPARED' ? 'primary' :
                                                    string === 'VEHICLE_ASSIGNED' ? 'primary' :
                                                        string === 'IN_DELIVERY' ? 'primary' :
                                                            string === 'SELF_PICKUP' ? 'secondary' :
                                                                string === 'PICKED_UP' ? 'success' :
                                                                    string === 'OVERDUE' ? 'danger' : 'light';
}

export function isSelfPickupOverdue(order: any) {
    if (order.isClickAndCollect && (calculateDayDiff(order.pickupDate) > 0) && (order.orderStateChanges[0].state === 'SELF_PICKUP')) {
        return true;
    } else if (order.orderStateChanges[0].state === 'OVERDUE') {
        return true;
    }
    return false;
}

export function calculateDayDiff(date) {
    const checkDate = dayjs(date)
        .set("hours", 0)
        .set("minutes", 0)
        .set("second", 0)
        .set("millisecond", 0);
    const today = dayjs()
        .set("hours", 0)
        .set("minutes", 0)
        .set("second", 0)
        .set("millisecond", 0);
    const diff = today.diff(checkDate, "days");
    return diff;
}
